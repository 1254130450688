<template>
    <ap-tile class="financing-request-contact-form--container" variant="light">
        <div class="financing-request-contact-form">
            <div class="financing-request-contact-form--header">
                <div class="financing-request-contact-form--header--preheader">
                    Digital, Sicher & Doch Persönlich
                </div>
                <h4 class="financing-request-contact-form--header--main font-weight-600">
                    Wir finden die beste Finanzierung für deine Immobilie
                </h4>
            </div>
            <div v-if="isLoading">
                <ap-tile class="financing-request-contact-form--request-sent-tile">
                    <div class="financing-request-contact-form--request-sent-tile--success">
                        <h5>Bitte <span class="font-weight-600">warten Sie einen Moment</span>, bevor Sie das Fenster schließen oder verlassen.</h5>
                        <div>
                            Wir überprüfen gerade, dass Ihre Daten korrekt bei uns eingegangen sind.
                        </div>
                    </div>
                </ap-tile>
            </div>
            <template v-else-if="hasSentRequest">
                <ap-tile class="financing-request-contact-form--request-sent-tile">
                    <h5 v-if="isError" class="financing-request-contact-form--request-sent-tile--error">
                        Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu versuchen Sie es nochmal.
                    </h5>
                    <div v-else class="financing-request-contact-form--request-sent-tile--success">
                        <h5>Vielen Dank!</h5>
                        <div class="financing-request-contact-form--request-sent-tile--success--highlighted-text">
                            Bitte <span class="font-weight-600">bestätige einmalig Deine E-Mail-Adresse</span> im Posteingang.
                        </div>
                        <h5>Wir melden uns innerhalb von 24 Stunden bei Dir.</h5>
                    </div>
                </ap-tile>
            </template>
            <template v-else>
                <div
                    v-for="inputField in inputFields"
                    :key="inputField.id"
                    class="financing-request-contact-form--input-fields">
                    <label :for="inputField.id">
                        {{ inputField.label }}
                        <span class="financing-request-contact-form--input-fields--asterisk">*</span>
                    </label>
                    <input
                        v-model="inputField.variable"
                        class="financing-request-contact-form--input-fields--input"
                        :id="inputField.id"
                        :type="inputField.type"
                        @input="clearInvalidState(inputField.id)"
                        @blur="checkValidity(inputField.id)" />
                </div>
                <ap-checkbox v-model="hasAcceptedPrivacyPolicy">
                    Ich akzeptiere die 
                    <a :href="privacyPolicyLink" target="_blank">Datenschutzerklärung</a>
                    <span class="financing-request-contact-form--input-fields--asterisk"> *</span>
                </ap-checkbox>
                <button
                    class="financing-request-contact-form--submit-button"
                    :disabled="!isCompleted"
                    @click="submitRequest()">
                    Kontaktanfrage Senden
                </button>
            </template>
        </div>
    </ap-tile>
</template>

<script setup>
import { computed, ref } from 'vue'
import externalAssets from '@/assets/external-assets'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()
const privacyPolicyLink = computed(() => externalAssets.links.acrossProperty.privacyPolicy)

const firstname = ref()
const lastname = ref()
const email = ref()
const phone = ref()
const hasAcceptedPrivacyPolicy = ref(false)
const emailRegex = /\S+@\S+\.\S+/
const isValidEmail = computed(() => emailRegex.test(email.value))
const isCompleted = computed(() =>
    firstname.value?.trim()
    && lastname.value?.trim()
    && isValidEmail.value
    && phone.value?.trim()
    && hasAcceptedPrivacyPolicy.value)
const isLoading = ref(false)
const hasSentRequest = ref(false)
const isError = ref(false)
const companyId = computed(() => route?.params?.companyId)
const partnerId = computed(() => route?.params?.partnerId)

const inputFields = ref([
    {
        id: 'firstname',
        label: 'Vorname',
        variable: firstname,
        type: 'text'
    },
    {
        id: 'lastname',
        label: 'Nachname',
        variable: lastname,
        type: 'text'
    },
    {
        id: 'email',
        label: 'E-Mail',
        variable: email,
        type: 'email'
    },
    {
        id: 'phone',
        label: 'Telefon / Handy',
        variable: phone,
        type: 'tel'
    },
])
const invalidClassName = 'financing-request-contact-form--input-fields--input--is-invalid'

const submitRequest = async () => {
    if (isCompleted.value) {
        try {
            isLoading.value = true
            const payload = {
                firstname: firstname.value,
                lastname: lastname.value,
                email: email.value,
                phone: phone.value,
                hasAcceptedPrivacyPolicy: hasAcceptedPrivacyPolicy.value,
                companyId: companyId.value,
                partnerId: partnerId.value
            }
            await store.dispatch('submitFinancingRequest', payload)
            isLoading.value = false
        } catch (error) {
            isError.value = true
        }
        hasSentRequest.value = true
    }
}

const clearInvalidState = (inputId) => {
    const inputField = document.getElementById(inputId)
    if (inputField.classList.contains(invalidClassName)) {
        inputField.classList.remove(invalidClassName)
    }
}

const checkValidity = (inputId) => {
    if (inputId === 'email' && !isValidEmail.value) {
        const inputField = document.getElementById(inputId)
        inputField.classList.add(invalidClassName)
    }
}
</script>

<style lang="scss" scoped>
.financing-request-contact-form--container {
    width: 32rem;
    margin: 0 1.5rem;
    z-index: 1000;
}

.financing-request-contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem;
}

.financing-request-contact-form--header {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &--preheader {
        color: #3FE395;
        font-size: 0.75rem;
        text-transform: uppercase;
    }

    &--main {
        color: #3B4868;
        font-size: 1.5rem;
    }
}

.financing-request-contact-form--request-sent-tile {
    margin: 1.5rem 0;

    &--success {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        gap: 1.5rem;
        text-align: center;
    }

    &--success--highlighted-text {
        color: #39B3B7;
    }

    &--error {
        color: #F23985;
    }
}

.financing-request-contact-form--input-fields {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &--asterisk {
        color: #F23985;
    }

    &--input {
        all: unset;
        background-color: white;
        border-radius: 0.5rem;
        border: 0.125rem solid #D9D9D9;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        height: 2.25rem;
        padding: 0 0.375rem;
        width: 100%;
    }

    &--input--is-invalid {
        color: #F23985;
    }
}

.financing-request-contact-form--submit-button {
    all: unset;
    background: #3FE395;
    border-radius: 0.5rem;
    color: #FFFFFF;
    cursor: pointer;
    filter: drop-shadow(0 0.25rem 0.5rem rgba(0, 0, 0, 0.12));
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25;
    padding: 0.625rem 1.125rem;
    width: fit-content;
    text-align: center;
}

.financing-request-contact-form--submit-button:hover:not(:disabled) {
    background-color: white;
    color: #3FE395;
}

.financing-request-contact-form--submit-button:disabled {
    background-color: rgba(#3B4868, 0.5);
}

@media (max-width: 1080px) {
    .financing-request-contact-form--container {
        min-width: 0;
        max-width: 50rem;
    }

    .financing-request-contact-form {
        margin: 0.5rem;
    }

    .financing-request-contact-form--header--main {
        font-size: 1.4rem;
    }
}
</style>