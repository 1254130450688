<template>
    <div class="customers-summary">
        <h3 class="customers-summary--header">Übersicht aller Kunden</h3>
        <div class="customers-summary--content">
            <ap-tile class="customers-summary--content--total-commission-tile">
                <h6 class="customers-summary--content--total-commission-tile--header">
                    Erwartete Provision Gesamt (brutto):
                </h6>
                <div class="customers-summary--content--total-commission-tile--amount">
                    {{ earningsAgentExpected }}
                </div>
                <div class="customers-summary--content--total-commission-tile--customers">
                    <img :src="userIcon" class="customers-summary--content--total-commission-tile--customers--icon" />
                    <div>
                        {{ dealsCountInfo }}
                    </div>
                </div>
            </ap-tile>
            <ap-tile class="customers-summary--content--custom-link-tile">
                <ap-copy-button v-model="contactLink" label="Ihr persönlicher Link um Kunden zu werben." />
            </ap-tile>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import userIcon from '../../assets/user-icon.svg'
import convertToLocaleCurrency from '@/essentials/helpers/convert-to-locale-currency'

const store = useStore()
const earningsAgentExpected = computed(() => convertToLocaleCurrency(store.state.earningsAgentExpected))
const dealsCountInfo = computed(() => `${store.state.deals.length} Kunden`)
const contactLink = computed(() => store.state.user?.contactLink)
</script>

<style lang="scss" scoped>
.customers-summary {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.customers-summary--header {
    font-weight: 700;
    margin: 0 0 0 0.5rem;
}

.customers-summary--content {
    display: flex;
    gap: 1rem;
    justify-content: start;
}

.customers-summary--content--total-commission-tile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 15.5rem;

    &--header {
        color: #39B3B7;
        margin: 0;
        max-width: 13rem;
    }

    &--amount {
        align-self: flex-end;
        font-size: 2.25rem;
    }

    &--customers {
        align-items: center;
        display: flex;
        gap: 0.25rem;
    }

    &--customers--icon {
        height: 1rem;
        width: 1rem;
    }
}

.customers-summary--content--custom-link-tile {
    min-width: 30rem;
}
</style>