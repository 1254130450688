<template>
    <label
        class="ap-switch"
        :class="{ disabled }"
        for="ap-switch">
        <input
            v-model="currentValue"
            type="checkbox"
            role="switch"
            id="ap-switch"
            :data-show-state-labels="showStateLabels"
            :disabled="disabled" />
        {{ label }}
    </label>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
    modelValue: Boolean,
    label: String,
    disabled: Boolean,
    showStateLabels: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const currentValue = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		// Needed for v-model, see https://vuejs.org/guide/components/v-model.html#v-model-arguments
		emit('update:modelValue', value)
	},
})
</script>

<style lang="scss" scoped>
// See https://web.dev/building-a-switch-component/
.ap-switch {
    --thumb-size: 1rem;
    --thumb: white;
    --thumb-highlight: rgba(black / 25%);
    --thumb-color: var(--thumb);
    --thumb-color-highlight: var(--thumb-highlight);
    --track-size: calc(var(--thumb-size) * 2);
    --track-padding: 0.125rem;
    --track-inactive: #D9D9D9;
    --track-active: #3FE395;
    --track-color-inactive: var(--track-inactive);
    --track-color-active: var(--track-active);

    -webkit-tap-highlight-color: transparent;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: calc(var(--thumb-size) * 0.75);
    font-weight: 600;
    gap: 1rem;
    justify-content: center;
    min-height: 1rem;
    position: relative;
    user-select: none;
    width: max-content;

    > input {
        --thumb-position: 0%;

        align-items: center;
        appearance: none;
        background: var(--track-color-inactive);
        border: none;
        border-radius: var(--track-size);
        box-sizing: content-box;
        block-size: var(--thumb-size);
        cursor: pointer;
        display: grid;
        flex-shrink: 0;
        grid: [track] 1fr / [track] 1fr;
        inline-size: var(--track-size);
        outline-offset: 0.3125rem;
        padding: var(--track-padding); 
    }

    > input:not(:disabled):hover::before {
        --highlight-size: .5rem;
    }

    > input::before {
        background: var(--thumb-color);
        block-size: var(--thumb-size);
        border-radius: 50%;
        box-shadow: 0 0 0 var(--highlight-size) var(--thumb-color-highlight);
        content: '';
        grid-area: track;
        inline-size: var(--thumb-size);
        transform: translateX(var(--thumb-position));
        transition:
            transform 300ms ease,
            box-shadow 250ms ease;
    }

    > input[data-show-state-labels=true]::after {
        color: black;
        content: "Aus";
        font-size: 0.5rem;
        text-align: right;
        position: absolute;
        left: calc(var(--thumb-size) + 0.175rem);
        width: calc(var(--thumb-size) - 0.125rem);
    }

    > input:checked {
        --thumb-position: calc(var(--track-size) - 100%);

        background: var(--track-color-active);
    }

    > input[data-show-state-labels=true]:checked::after {
        color: white;
        content: "An";
        font-size: 0.5rem;
        text-align: right;
        position: absolute;
        left: 0;
        width: calc(var(--thumb-size) - 0.125rem);
    }

    > input:disabled {
        --thumb-color: transparent;

        cursor: not-allowed;

        &::before {
            cursor: not-allowed;
            box-shadow: inset 0 0 0 0.125rem rgba(#D9D9D9, 50%);
        }
    }
}
</style>