import onClickOutside from "../directives/on-click-outside"

export default (app) => {
    essentialDirectives.forEach(directive => {
        app.directive(directive.name, directive.value)
    })
}

const essentialDirectives = [
    onClickOutside
]
