<template>
    <!-- initial email entry -->
    <div id="login-page-bg">
        <div class="login-form-box">
            <img class="ap-logo-login" src="../assets/ap-logo.png" alt="AP Logo">
            <!-- <div class="register-here">Haben Sie noch keinen Account? <span class="link" @click="sendToRegistration">Registrieren</span></div> -->
            <form @submit.prevent="login()" class="login-form">
                <div>
                    <label for="email" class="form-label mt-4">E-Mail-Adresse</label>
                    <div>
                        <input class="form-control" ref="start" id="email" for="email" type="email" placeholder="E-Mail"
                            v-model="username" />
                    </div>
                    <label for="password" class="form-label mt-4">Passwort</label>
                    <div>
                        <input class="form-control" id="password" for="password" type="password" placeholder="Passwort"
                            v-model="password" />
                    </div>
                    <button v-bind:disabled="usernameLength == 0 ||
                    passwordLength == 0" class="my-4  btn button-ap-1 ">
                        Anmelden
                    </button>
                </div>
                <div v-if="hasLoginFailed"><span class="invalid-input">Email oder Passwort sind falsch</span></div>
                <!-- <div><span>passwort vergessen</span></div> -->
                <!-- <router-link to="/registration">Registrieren</router-link> -->
            </form>
            <!-- <div class="register-here link" @click="sendToForgotPassword">Passwort vergessen?</div> -->
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const start = ref('start')
const isAuthenticated = computed(() => store.state.isAuthenticated)
const hasLoginFailed = computed(() => store.state.loginAttempts > 0 && !isAuthenticated.value)

const username = ref('')
const password = ref('')

const usernameLength = computed(() => username.value.length)
const passwordLength = computed(() => password.value.length)

const login = async () => {
    store.commit('setUsername', username.value)
    store.commit('setPassword', password.value)
    await store.dispatch('sendLoginCredentials')
    if (isAuthenticated.value) {
        await store.dispatch('getUser', username.value)
        await router.push({ name: 'financing' })
    }
}

onMounted(() => {
    username.value = store.state.authentication.username
    store.state.loginAttempts = 0
    start.value.focus()
})
</script>

<style>
.ap-logo-login {
    width: 280px;
    margin: 0px 0 10px 0;
}
.link {
    color: blue;
    cursor: pointer;
}
.link:hover {
    text-decoration: underline;
}
.register-here {
    font-size: 13px;
}
.login-form {
    margin: 20px auto 0;
}
.login-form-box {
    background-color: #fff;
    padding: 50px 60px;
    border-radius: 10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
#login-page-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #39B3B7, #3FE396, #1ec6cc, #3fd38e);
    background-size: 200% 200%;
    animation: gradient 12s ease infinite;
}
/* animated background */
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>