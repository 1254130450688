
import loadComponents from '@/essentials/loaders/load-components'
import loadDirectives from '@/essentials/loaders/load-directives'
import { createApp } from 'vue'

export default (dialogComponent, props) =>
    new Promise((resolve, reject) => {
        const divElement = document.createElement('div')
        document.body.appendChild(divElement)

        const onResolve = (value) => resolve(value)
        const onReject = (value) => reject(value)
        const onClose = () => {
            divElement.parentNode.removeChild(divElement)
            dialogApp.unmount()
            dialogApp = null
            resolve()
        }

        let dialogApp = createApp(dialogComponent, {
            ...props,
            onResolve,
            onReject,
            onClose,
        })
        loadComponents(dialogApp)
        loadDirectives(dialogApp)
        dialogApp.mount(divElement)
    })
