<template>
    <h1>Renditeansicht</h1>
    <div class="row">
        <div class="col-7">
            <area-chart
                :chartData="store.state.chartsData.timeReturnChart"
                id="time-return-chart"
                :filename="returnChartFilename"
                title="Rendite"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Mietrendite'
                yaxisUnit="%" />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <area-chart
                :chartData="store.state.chartsData.timePriceChart"
                id="time-price-chart"
                filename="Quadratmeterpreis"
                title="Quadratmeterpreis"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Preis pro qm'
                yaxisUnit="€" />
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <area-chart
                :chartData="store.state.chartsData.timeRentChart"
                id="time-price-chart"
                filename="Quadratmetermiete"
                title="Quadratmetermiete"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Miete pro qm'
                yaxisUnit="€" />
        </div>
    </div>
</template>

<script setup>
import AreaChart from './AreaChart.vue'

import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()


const location = computed(() => {
    let location = ""
    if (store.state.geoSearchCat == "community") {
        location = store.state.community
    } else if (store.state.geoSearchCat == "city") {
        location = store.state.city
    } else if (store.state.geoSearchCat == "postcode") {
        location = store.state.postcode
    }
    return location
})

const returnChartFilename = computed(() => `Rendite in ${location.value}`)
const subtitleTimeCharts = computed(() =>  store.state.timeChartSubtitle)


</script>