<template>
    <div class="ap-selector-switch">
        <button
            v-for="option in options"
            :key="option.id"
            :class="selectedOptionStyle(option.id)"
            @click="select(option)">
            {{ option.title }}
        </button>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
    value: Object,
    options: Array, // TODO: option should have an id, title and value field
})

const emit = defineEmits(['select'])

const selectedOption = ref(props.value)

const select = (option) => {
    selectedOption.value = option
    emit('select', selectedOption.value)
}

const selectedOptionStyle = (id) => selectedOption.value?.id === id ? 'selected-option' : undefined

</script>

<style lang="scss" scoped>
.ap-selector-switch {
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.3125rem rgba(black, 15%);
	cursor: pointer;
    display: flex;
    justify-content: center;
    
    > button {
        background-color: white;
        border: 0.0625rem #D9D9D9 solid;
        color: #3B4868;
        flex-grow: 1;
        font-size: 0.875rem;
        line-height: 1;
        padding: 0.75rem 1.5rem;
    }

    > button:hover {
        background-color: rgba(#3FE395, 0.25);
    }

    > .selected-option,
    > .selected-option:hover {
        background-color: #3FE395;
        color: white;
    }

    > button:first-child {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }

    > button:last-child {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
}
</style>