<template>
    <div class="row">
        <button class="col btn button-ap-1" @click="this.$store.commit('setDashboardView', 'ReturnView')">Renditeansicht</button>
        <button class="col btn button-ap-1" @click="this.$store.commit('setDashboardView', 'MarketView')">Marktansicht</button>
        <button class="col btn button-ap-1" @click="this.$store.commit('setDashboardView', 'RoomsView')">Vergleichsansicht</button>
    </div>
</template>

<script>
export default {
    name: 'SideNavDashboard'
}
</script>