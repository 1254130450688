<template>
    <div class="solar-financing-finder-extended-form">
        <h4 class="solar-financing-finder-extended-form--header">
            Angaben zur Immobilie und Antragsteller*in
        </h4>
        <div class="solar-financing-finder-extended-form--body">
            <div class="solar-financing-finder-extended-form--body--content-box">
                <div class="solar-financing-finder-extended-form--body--content-box--row">
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Immobilienart</h6>
                        <ap-dropdown
                            :value="propertyTypeSolarFinancing"
                            :options="propertyTypes"
                            limit-max-size="4"
                            @select="selectPropertyType($event)" />
                    </div>
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Wohnfläche (in qm)</h6>
                        <ap-input v-model="livingArea" type="number" @input="updateLivingArea()" />
                    </div>
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Baujahr</h6>
                        <ap-input v-model="constructionYear" type="number" @input="updateConstructionYear()" />
                    </div>
                </div>
                <div class="solar-financing-finder-extended-form--body--content-box--row">
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>PLZ</h6>
                        <ap-input v-model="zipCode" type="zipcode" @input="updateZipCode()" />
                    </div>
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Beschäftigungsart</h6>
                        <ap-dropdown
                            :value="employmentType"
                            :options="employmentTypes"
                            limit-max-size="4"
                            @select="selectEmploymentType($event)" />
                    </div>
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Net. Einkommen (in € mtl.)</h6>
                        <ap-input v-model="netIncome" type="number" @input="updateMonthlyIncome()" />
                    </div>
                </div>
                <div class="solar-financing-finder-extended-form--body--content-box--row">
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Geburtsdatum</h6>
                        <ap-input v-model="dateOfBirth" type="date" @input="updateDateOfBirth()" />
                    </div>
                    <div class="solar-financing-finder-extended-form--body--content-box--row--wide-element">
                        <h6>Gewünschte Rate (in € mtl.)</h6>
                        <ap-input v-model="preferredMonthlyRate" type="number" @input="updatePreferredMonthlyRate()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const propertyTypeSolarFinancing = computed(() => store.state.propertyTypeSolarFinancing)
const livingArea = ref()
const constructionYear = ref()
const zipCode = ref()
const employmentType = computed(() => store.state.employmentType)
const monthlyIncome = ref()
const dateOfBirth = ref()
const preferredMonthlyRate = ref()

const propertyTypes = computed(() => store.state.propertyTypes ?? [])
const employmentTypes = computed(() => store.state.employmentTypes ?? [])

const selectPropertyType = (type) => store.commit('updatePropertyTypeSolarFinancing', type)
const updateLivingArea = () => store.commit('updateLivingArea', livingArea.value)
const updateConstructionYear = () => store.commit('updateConstructionYear', constructionYear.value)
const updateZipCode = () => store.commit('updateZipCode', zipCode.value)
const selectEmploymentType = (type) => store.commit('updateEmploymentType', type)
const updateMonthlyIncome = () => store.commit('updateMonthlyIncome', monthlyIncome.value)
const updateDateOfBirth = () => store.commit('updateDateOfBirth', dateOfBirth.value)
const updatePreferredMonthlyRate = () => store.commit('updatePreferredMonthlyRate', preferredMonthlyRate.value)
</script>

<style lang="scss" scoped>
.solar-financing-finder-extended-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.solar-financing-finder-extended-form--header {
    color: #3B4868;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    margin: 0;
}

.solar-financing-finder-extended-form--body {
    display: flex;
    gap: 2rem;

    &--content-box {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }

    &--content-box--row {
        display: flex;
        gap: 1.5rem;
    }

    &--content-box--row--wide-element {
        flex: 2 1 100%;
    }
}

@media (max-width: 680px) {
    .solar-financing-finder-extended-form--body--content-box--row {
            flex-wrap: wrap;
    }
}
</style>