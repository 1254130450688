<template>
    <div class="deal-contacts">
        <h6 class="font-weight-600">
            Kontakte:
        </h6>
        <h6>
            {{ formattedContacts }}
        </h6>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
    contacts: Object,
})

const formattedContactNames = computed(() => props.contacts.map(contact => `${contact.firstname} ${contact.lastname}`))
const formattedContacts = computed(() => formattedContactNames.value.join(', '))
</script>

<style lang="scss" scoped>
.deal-contacts {
    display: flex;
    gap: 1rem;
}
</style>