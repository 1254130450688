const inputTypes = {
	area: {
		attrs: {
			type: 'text',
			inputMode: 'decimal',
			placeholder: 'Bitte eingeben...',
		}
	},
	copy: {
		attrs: {
			type: 'text',
			disabled: true,
		}
	},
	date: {
		attrs: {
			type: 'date',
			placeholder: 'TT.MM.JJJJ',
		}
	},
	email: {
		attrs: {
			type: 'email',
			placeholder: 'Bitte eingeben...',
		}
	},
	number: {
		attrs: {
			type: 'number',
			placeholder: 'Bitte eingeben...',
		}
	},
	password: {
		attrs: {
			type: 'password',
			placeholder: 'Passwort',
		}
	},
	percentage: {
		attrs: {
			type: 'number',
			step: '0.01',
			min: '0',
			max: '100',
			placeholder: 'Bitte eingeben...',
		}
	},
	price: {
		attrs: {
			type: 'text',
			inputMode: 'decimal',
			placeholder: 'Bitte eingeben...',
		}
	},
	search: {
		attrs: {
			type: 'search',
			placeholder: 'Suchen...',
		}
	},
	tel: {
		attrs: {
			type: 'tel',
			placeholder: 'Bitte eingeben...'
		}
	},
	text: {
		attrs: {
			type: 'text',
			placeholder: 'Bitte eingeben...',
		}
	},
	zipcode: {
		attrs: {
			type: 'text',
			maxlength: 5,
			placeholder: 'Bitte eingeben...',
		}
	}
}

export default inputTypes
