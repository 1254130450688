<template>
    <div ref="financeInputsRef">
        <div class="container-fluid">
            <div class="row"><div class="col-xl-8">
            <div class="input-outer-box">
                <div class="intro mx-2">
                <h3>Vergleich von +500 Anbietern</h3>
                <h1 style="font-size: 3rem; color: #3fe395; font-weight: 600; line-height: 4rem;"><span>Erhalte Deine </span><span>Zins-Konditionen<br>für die </span>Baufinanzierung</h1>
                <p class="mb-5 mt-4">Mit nur wenigen Angaben erhälst Du realistische Finanzierungsvorschläge für Dein Vorhaben.
                </p>
            </div>
                <form @submit.prevent="loadFinancingProposals()" id="finance-proposals-form">
                    <div class="row">
                        <div class="col-md-6">
                        <div class=" adresse ap-tile p-4 qx-2 mx-2 mb-2">
                            <h4>Angaben zur Immobilie</h4>
                            <div class="row">
                                <div class="col gx-2">
                                    <div>
                                        <label for="property-type">Immobilientyp</label>
                                        <select name="" id="property-type" v-model="financingData.propertyType" required>
                                            <option value="EIGENTUMSWOHNUNG">Eigentumswohnung</option>
                                            <option value="EINFAMILIENHAUS">Einfamilienhaus</option>
                                            <option value="DOPPELHAUSHAELFTE">Doppelhaushälfte</option>
                                            <option value="REIHENHAUS">Reihenhaus</option>
                                            <option value="ZWEIFAMILIENHAUS">Zweifamilienhaus</option>
                                            <option value="MEHRFAMILIENHAUS">Mehrfamilienhaus</option>
                                            <option value="BAUGRUNDSTUECK">Grundstück</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col gx-2">
                                    <div>
                                        <label for="construction-year">Baujahr <span>(o. komplettsanierung)</span></label>
                                        <input type="number" id="construction-year" :min="1800" :max="2025" v-model="financingData.constructionYear" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col gx-2">
                                    <div>
                                        <label for="living-area">Wohnfläche in m<sup>2</sup> (auf volle m<sup>2</sup> runden)</label>
                                        <input type="number" id="living-area" :min="20" :max="1000" v-model="financingData.livingArea" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-9 gx-2">
                                    <div>
                                        <label for="street">Straße</label>
                                        <input type="text" id="street" v-model="financingData.street">
                                    </div>
                                </div>
                                <div class="col-md-3 gx-2">
                                    <div>
                                        <label for="house-number">Hausnr.</label>
                                        <input type="text" id="house-number" v-model="financingData.houseNumber">
                                    </div>
                                </div>
                            </div>    
                            <div class="row">
                                <div class="col-md-3 gx-2">
                                    <div>
                                        <label for="postcode">PLZ</label>
                                        <input type="text" id="postcode" pattern="[0-9]{5}" v-model="financingData.postcode" required>
                                    </div>
                                </div>
                                <div class="col-md-9 gx-2">
                                    <div>
                                        <label for="city">Stadt / Ort</label>
                                        <input type="text" id="city" v-model="financingData.city" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                    <div class="immobilie ap-tile p-4 mx-2">
                        <h4>Angaben zum Finanzierungsvorhaben</h4>
                        <div class="row">

                            <div class="col gx-2">
                                <div>
                                    <label for="financing-purpose">Finanzierungszweck</label>
                                    <select name="" id="financing-purpose" v-model="financingData.financingPurpose" required>
                                        <option value="KAUF">Kauf</option>
                                        <option value="KAUF_NEUBAU_VOM_BAUTRAEGER">Kauf vom Bauträger</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col gx-2">
                                <div>
                                    <label for="price">Kaufpreis</label>
                                    <input type="number" id="price" :min="1000" :max="10000000" :step="1000" lang="de-DE" v-model="financingData.price" required>
                                    <div v-if="financingData.price">{{ financingData.price.toLocaleString('de') }} €</div>
                                </div>
                            </div>
                            <div class="col gx-2">
                                <div>
                                    <label for="modernization-cost">Modernisierungskosten</label>
                                    <input type="number" id="modernization-cost" :min="0" :max="10000000" :step="100" v-model="financingData.modernizationCost" required>
                                    <div v-if="financingData.modernizationCost">{{ financingData.modernizationCost.toLocaleString('de') }} €</div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="ap-flex mt-3">
                            <input class="ap-checkbox" type="checkbox" id="commercial-usage" v-model="financingData.comercialUsage">
                            <label class="ap-checkbox-label" for="commercial-usage">Immobilie ist gewerblich genuzt</label>
                        </div>
                    
                        <div class="ap-flex mt-3">
                            <input class="ap-checkbox" type="checkbox" id="is-rented-out" v-model="financingData.isRentedOut">
                            <label class="ap-checkbox-label" for="is-rented-out">Immobilie ist Kapitalanlage</label>
                        </div>
                    
                        <div v-if="financingData.isRentedOut">
                            <label for="rent">Kaltmiete (geplant)</label>
                            <input type="number" id="rent" v-model="financingData.rent">
                            <div v-if="financingData.rent">{{ financingData.rent.toLocaleString('de') }} €</div>
                        </div>
                    </div>
                </div>
                </div>
                    <div class="row customer-inputs ap-tile p-4 mt-2 mx-2">
                        <h4>Angaben zum Kunden</h4>
                        <div class="row">
                            <div class="col-md gx-2">
                                <div>
                                    <label for="wish-rate">Gewünschte Rate</label>
                                    <input type="number" id="wish-rate" :min="10" :max="1000000" :step="10" v-model="customerData.monthlyRate" required>
                                    <div v-if="customerData.monthlyRate">{{ customerData.monthlyRate.toLocaleString('de') }} €</div>
                                </div>
                            </div>
                            <div class="col-md gx-2">
                                <div class="ap-input">
                                    <label for="equity">Eigenkapital</label>
                                    <input type="number" id="equity" :min="0" :max="100000000" :step="100" v-model="customerData.ownFunds" required>
                                    <div v-if="customerData.ownFunds">{{ customerData.ownFunds.toLocaleString('de') }} €</div>
                                </div>
                            </div>
                            <div class="col-md gx-2">
                                <div>
                                    <label for="birth-date">Geburtsdatum</label>
                                    <input type="date" id="birth-date" v-model="customerData.birthDate" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md gx-2">
                                <div>
                                    <label for="income">Monatliches Einkommen (netto)</label>
                                    <input type="number" id="income" :min="0" :max="1000000" :step="10" v-model="customerData.income" required>
                                    <div v-if="customerData.income">{{ customerData.income.toLocaleString('de') }} €</div>
                                </div>
                            </div>
                            <div class="col-md gx-2">
                                <div>
                                    <label for="employment-type">Beschäftigungsart</label>
                                    <select name="" id="employment-type" v-model="customerData.employmentType" required>
                                        <option value="ANGESTELLTER">Angestellt</option>
                                        <option value="ARBEITER">Arbeiter:in</option>
                                        <option value="BEAMTER">Verbeamtet</option>
                                        <option value="FREIBERUFLER">Freiberuflich</option>
                                        <option value="SELBSTAENDIGER">Selbstständig</option>
                                        <option value="RENTNER">In Rente</option>
                                        <option value="HAUSFRAU">Hausmann / Hausfrau</option>
                                        <option value="ARBEITSLOSER">Arbeitslos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isFurtherOptions" class="row mt-3" style="cursor:pointer" @click="toggleFurtherOptions()">+ weitere Optionen</div>
                        <div v-else class="row mt-3" style="cursor:pointer" @click="toggleFurtherOptions()">- weitere Optionen</div>
                        <div v-if="isFurtherOptions" class="row">
                            <div class="col-md gx-2">
                                <div>
                                    <label for="fixedInterest">Zinsbindung</label>
                                    <input type="number" id="fixedInterest" :min="0" :max="30" :step="1" v-model="customerData.fixedInterest"> Jahre
                                </div>
                            </div>
                            <div class="col-md gx-2">
                                <div>
                                    <label for="commitmentInterestFreeTime">Bereitstellungszinsfreie Zeit (BZ-freie Zeit)</label>
                                    <input type="number" id="commitmentInterestFreeTime" :min="0" :max="36" :step="1" v-model="customerData.commitmentInterestFreeTime"> Monate
                                </div>
                            </div>
                        </div>

                    </div>

                    <button class="btn button-ap-1 mt-5" type="submit" @click="pushSubmitEvent()" ref="submitButton">Finanzierungen Laden</button>
                </form>
            </div>
        </div>
        <div class="col-xl-4">
        <FinanceCTA/>
        </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import FinanceCTA from '@/components/FinanzierungMakler/FinanceCTA.vue'

const store = useStore()

const financeInputsRef = ref()
const financingData = ref(store.state.financingData)
const customerData = ref(store.state.customerData)
const submitButton = ref()
const isFurtherOptions = ref(false)

const loadFinancingProposals = async () => {
    store.state.financingData = financingData.value
    store.state.customerData = customerData.value
    window.scrollTo(0, financeInputsRef.value?.scrollHeight)
    await store.dispatch('getFinancingProposalsCustomerSpecific')
    window.scrollTo(0, financeInputsRef.value?.scrollHeight)
}

const pushSubmitEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'form-submit-finance-proposals',
        'agent': store.state.contact,
        'financingData':  store.state.financingData,
        'customerData':  store.state.customerData,
    })
}

const toggleFurtherOptions = () => {
    isFurtherOptions.value = !isFurtherOptions.value
    if (!isFurtherOptions.value) {
        store.state.customerData.fixedInterest = false
        store.state.customerData.commitmentInterestFreeTime = false
    }
}

onMounted(() => {
    store.commit('setLoadStateData', 2)
    store.commit('clearFinancingProposals')
})
</script>

<style scoped>
h2 {
    color: #3B4868;
    font-weight: 600;
}
h3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    color: #39B3B7;
}
.hero {
    margin: 1rem 0 0 1rem;
}

label span {
    font-size: 0.8rem;
}
label {
    margin-bottom: 0.5rem;
    margin-top: 0.7rem;
    display: block;
}
input, select {
    border-radius: 0.3rem;
    height: 2rem;
    border: 0.0625rem solid #C2C9D1;
    padding: 0 0.3rem;
}
input[type=date] {
    cursor: pointer;
}
input, select{
    width: 100%;
}
input:focus, select:focus {
    outline: none !important;
    border:0.125rem solid #39B3B7;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

}
.row {
    --bs-gutter-x: 0;
}
h4 {
    font-weight: 600;
    font-size: 1.25rem;
    color: #39B3B7;
}
.input-outer-box {
    background-color: #F8FBFE;
    border-radius: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 1rem 0.5rem 0 0.5rem;
    padding: 2rem;
}

.ap-checkbox-label {
    display: inline;
    margin: 0;
    cursor: pointer
}
.ap-checkbox {
    width: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    height: auto;
}
select {
    cursor: pointer;
}
.ap-flex {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}
.customer-inputs {
    max-width: 1200px;
}
input[type=number] {
    -moz-appearance:textfield;
    -webkit-appearance: none;
    appearance: textfield;
}
</style>