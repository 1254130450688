export default {
    name: 'on-click-outside',
    value: {
        mounted(component, binding) {
            component.clickOutsideEvent = function(event) {
                if (!(component === event.target || component.contains(event.target))) {
                    binding.value(event, component)
                }
            }
            document.body.addEventListener('click', component.clickOutsideEvent)
        },
        unmounted(component) {
            document.body.removeEventListener('click', component.clickOutsideEvent)
        }
    }
}
