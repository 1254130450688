<template>
    <svg class="ap-spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle
            class="ap-spinner--circle"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30" />
    </svg>
</template>

<style lang="scss" scoped>
$offset: 187;
$duration: 1400ms;

.ap-spinner {
    animation: rotator $duration linear infinite;
    height: 4rem;
    margin: auto;
    width: 4rem;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.ap-spinner--circle {
    animation:
        dash $duration ease-in-out infinite, 
        colors ($duration * 4) ease-in-out infinite;
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
}

@keyframes colors {
    0% {
        stroke: #3fe396;
    }
    25% {
        stroke: #39b3b7;
    }
    50% {
        stroke: #3b4868;
    }
    75% {
        stroke: #f23985;
    }
    100% {
        stroke: #ffd130;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }
    50% {
        stroke-dashoffset: $offset / 4;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
</style>
