<template>
    <h1>Vergleichsansicht</h1>
    <div class="row mx-4">
        <div class="col-6 mt-5">
            <h2 class="d-flex justify-content-center">Kauf</h2>
            <RoomsCountChart />
            <TimePricePerRoomsChart />
            <TimeSupplyPerRoomsChart />
            <TimeDwellPerRoomsChart />
        </div>
        <div class="col-6 mt-5">
            <h2 class="d-flex justify-content-center">Miete</h2>
            <RoomsCountRentChart />
            <TimeRentPerRoomsChart />
            <TimeSupplyRentPerRoomsChart />
            <TimeDwellRentPerRoomsChart />
        </div>
    </div>
</template>

<script>

import RoomsCountChart from './PurchaseCharts/RoomsCountChart.vue'
import TimePricePerRoomsChart from './PurchaseCharts/TimePricePerRoomsChart.vue'
import TimeSupplyPerRoomsChart from './PurchaseCharts/TimeSupplyPerRoomsChart.vue'
import TimeDwellPerRoomsChart from './PurchaseCharts/TimeDwellPerRoomsChart.vue'

import RoomsCountRentChart from './RentCharts/RoomsCountRentChart.vue'
import TimeRentPerRoomsChart from './RentCharts/TimeRentPerRoomsChart.vue'
import TimeSupplyRentPerRoomsChart from './RentCharts/TimeSupplyRentPerRoomsChart.vue'
import TimeDwellRentPerRoomsChart from './RentCharts/TimeDwellRentPerRoomsChart.vue'


export default {
    name: 'RoomsView',

    components: {
        RoomsCountChart,
        TimePricePerRoomsChart,
        TimeSupplyPerRoomsChart,
        TimeDwellPerRoomsChart,
        
        RoomsCountRentChart,
        TimeRentPerRoomsChart,
        TimeSupplyRentPerRoomsChart,
        TimeDwellRentPerRoomsChart,
    }
}
</script>