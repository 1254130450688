export default (price) => {
    const priceAsNumber = Number(price)
    const isInt = Number.isInteger(priceAsNumber)
    const minimumFractionDigits = isInt ? 0 : 2
    const localeOptions = {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits,
    }
    return priceAsNumber.toLocaleString('de', localeOptions)
}
