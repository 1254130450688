<template>
	<div id="bootstrap-overrides" :class="navbarStyling">
		<TopNavbar v-if="showNavbar" />
		<div v-if="showNavbar" id="spacing-navbar"></div>
		<router-view />
	</div>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import TopNavbar from './components/Generel/TopNavbar.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()
const showNavbar = computed(() => Boolean(route.meta.requiresAuth))
const navbarStyling = computed(() => showNavbar.value ? 'add-margin-top-for-navbar' : undefined)

onMounted(async () => {
	document.title = "Across Property"
	if (store.state.authentication?.username) {
		await store.dispatch('getUser', store.state.authentication.username)
	}
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;600;700&display=swap');
@import url('https://weloveiconfonts.com/api/?family=fontawesome');

.add-margin-top-for-navbar {
	margin-top: 60px;
}

#app {
	background-color: #FFF;
	height: 100%;
}

/* fontawesome class*/
[class*="fontawesome-"]:before {
	font-family: 'FontAwesome', sans-serif;
}

* {
font-family: "Outfit", 'Inter', Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #3B4868;
}

* .font-weight-600 {
	color: inherit;
	font-size: inherit;
	font-weight: 600;
}

* .font-weight-700 {
	color: inherit;
	font-size: inherit;
	font-weight: 700;
}

#bootstrap-overrides h1 {
	color: #3B4868;
	font-size: 2rem;
}

h4 {
	color: #3B4868;
}

.spacer-top {
	padding-top: 60px;
}

.spacer-bottom {
	margin-top: 100px;
}

/* spacing to compensate for fixed navbar height */
#spacing-navbar {
	padding-top: 20px;
}

/* link color */
#bootstrap-overrides a {
	text-decoration: none;
	color: #39b3b7;
	transition: color .2s;
}

#bootstrap-overrides a:hover {
	color: #2B898C;
}

.invalid-input {
	color: #ff3300;
	font-size: 14px;
}

input::placeholder {
	color: rgb(170, 170, 170) !important;
}

.info-icon {
	width: 15px;
	margin-left: 5px;
}

.icon {
	width: 15px;
}

/* Tooltip container */
.tooltip-info {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltip-info .tooltiptext-info {
	visibility: hidden;
	background-color: #999;
	color: #fff;
	text-align: center;
	margin-left: 5px;
	padding: 5px 10px;
	border-radius: 6px;
	top: -5px;
	left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-info:hover .tooltiptext-info {
	visibility: visible;
}

.form-range {
	margin-top: 7px;
}

/* tiles (box) around communities and listings*/
.ap-tile {
	border: 1px solid #ced4da;
	border-radius: 10px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	padding: 2.5rem;
	transition: all 0.5s;
}

.ap-tile-market {
	min-height: 0px;
}

.ap-tile:hover {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.30);
}

/* "button-ap-1" best combined with bootstrap "btn" class */
#bootstrap-overrides .button-ap-1 {
	background-color: #3fe396;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #3fe396;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-1:hover {
	background-color: #fff;
	color: #3fe396;
}

/* "button-ap-2" best combined with bootstrap "btn" class */
#bootstrap-overrides .button-ap-2 {
	background-color: #39b3b7;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #39b3b7;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-2:hover {
	background-color: #fff;
	color: #39b3b7;
}

#bootstrap-overrides .button-ap-3 {
	background-color: #39b3b7;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #39b3b7;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-3:hover {
	background-color: #fff;
	color: #39b3b7;
}

/* feedback button fixed to bottom right */
.button-feedback {
	position: fixed;
	bottom: 50px;
	right: 30px;
	z-index: 960;
	animation: wobble 11s ease infinite;
}

/* animate wobble feedback button every 10 seconds for 1 second */
@keyframes wobble {
	0% {
		transform: translate(0, 0)
	}

	2.273% {
		transform: translate(5px, 0)
	}

	4.545% {
		transform: translate(0, 0)
	}

	6.818% {
		transform: translate(5px, 0)
	}

	9.091% {
		transform: translate(0, 0)
	}

	100% {
		transform: translate(0, 0)
	}
}


/* like-button */
.button-like {
height: 42px;
width: 42px;
border: solid 1px #ccc;
border-radius: 50%;
background-color: #fff;
box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
transition: all 0.3s;
}

.button-like:hover {
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.button-like .fontawesome-heart-empty {
font-size: 25px;
margin-left: 1px;
display: inline-block;
margin-top: 2px;
transition: all 0.3s;
}

/* same as above just for unliking instead of liking. There is a better way to do this */
.button-unlike {
background-color: #3fe396;
border: solid 1px #3fe396;
height: 42px;
width: 42px;
border-radius: 50%;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
transition: all 0.3s;
}

.button-unlike:hover {
box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.button-unlike .fontawesome-heart-empty {
color: #fff;
font-weight: 800;
font-size: 25px;
margin-left: 1px;
display: inline-block;
margin-top: 1px;
transition: all 0.3s;
}

.emphasize-hardfact {
font-weight: 600;
font-size: 1.15rem;
text-decoration: underline #3fe396 2px
}

.emphasize-hardfact-secondary {
font-weight: 600;
font-size: 1rem;
text-decoration: underline #39b3b7 2px;
}

.hardfact-tag {
font-size: 0.8rem;
}

.set-aside-parent {
position: relative;
}

.set-aside-child {
position: absolute;
left: 90px;
top: 40px;
}

.arrow-kaufpreis-ek {
width: 40px;
position: absolute;
left: 40px;
top: 47px;
}

.dreiklang {
text-align: center;
box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
border-radius: 10px;
background-color: #fff;
padding: 5px
}

.dreiklang-middle {
border-left: rgba(0, 0, 0, 0.1) 1px solid;
border-right: rgba(0, 0, 0, 0.1) 1px solid;
}

@media (max-width: 770px) {
.like-toggle {
	float: right;
	margin: 0px 0px 0px 10px;
}
}

/* badges on market segment overview tiles */
#bootstrap-overrides .badge-ap {
	background-color: #3fe396;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
}

/* input range (Sliders) */
#bootstrap-overrides .form-range::-webkit-slider-thumb {
	background: #39b3b7;
}

#bootstrap-overrides .form-range::-webkit-slider-thumb:active {
	background: #39b3b7a8;
}

.description{
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	text-align: left;
}

.background-broker {
	position: fixed;
	height: 100%;
	width: 100%;
	/* margin: 0 auto; */
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.5;
	z-index: 970;
}


.very-small-text {
	font-size: 0.6rem
}

.small-text {
	font-size: 0.8rem
}

.clickable {
	cursor: pointer
}

.selected {
	font-size: 0.8rem;
	font-weight: bold;
	color: #39b3b7;
}

.unselected {
	font-size: 0.6rem;
	cursor: pointer;
	vertical-align: middle;
}

.warning {
	font-size: 0.6rem;
	color: red;
}

.background-darkend {
	position: fixed;
	height: 100%;
	width: 100%;
	/* margin: 0 auto; */
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.5;
	z-index: 998;
}
</style>
