<template>
    <div class="proposal-display">
        <div v-if="loadStateData == 3">
            Leider ist ein Fehler vorgefallen. Bitte versuchen Sie es erneut. Sollte der Fehler öfter vorkommen, versuchen Sie es später noch einmal.
        </div>
        <div v-else-if="loadStateData == 1" class="row justify-content-center">
            <ap-spinner />
        </div>
        <div v-else class="container-fluid">

            <div v-if="proposals.length > 0" class="proposals-outer-box" id="finance-proposals-results">
                <h3>Ergebnisse</h3>
                <h2 class="mb-5">Finanzierungsvorschläge</h2>
                <button v-if="isOptions" @click="toggleOptions" class="options options-open"><img class="finance-icon" src="@/assets/tune_FILL0_wght400_GRAD0_opsz48.svg"/> Optionen verbergen</button>
                <button v-else @click="toggleOptions" class="options"><img class="finance-icon" src="@/assets/tune_FILL0_wght400_GRAD0_opsz48.svg"/> Optionen anzeigen</button>

                <div v-if="isOptions" class="dropdown-options">
                    <div class="spalten-anzeigen">Spalten anzeigen / verbergen:</div>
                    
                    <div class="options-container">

                    <div class="option">
                        <div @click="selectColumn('tilgungssatzInProzent')" v-if="displayColumns['tilgungssatzInProzent']" class="selected"><i class="material-icons">check_box</i>Tilgungssatz</div>
                        <div @click="selectColumn('tilgungssatzInProzent')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Tilgungssatz</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('steuerErsparnisMonatlichBeiHoechststeuersatz')" v-if="displayColumns['steuerErsparnisMonatlichBeiHoechststeuersatz']" class="selected"><i class="material-icons">check_box</i>Steuerersparnis mtl.</div>
                        <div @click="selectColumn('steuerErsparnisMonatlichBeiHoechststeuersatz')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Steuerersparnis mtl.</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('laufzeit')" v-if="displayColumns['laufzeit']" class="selected"><i class="material-icons">check_box</i>Laufzeit</div>
                        <div @click="selectColumn('laufzeit')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Laufzeit</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('zinsbindungInJahren')" v-if="displayColumns['zinsbindungInJahren']" class="selected"><i class="material-icons">check_box</i>Zinsbindung</div>
                        <div @click="selectColumn('zinsbindungInJahren')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Zinsbindung</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('restschuldNachZinsbindungsEnde')" v-if="displayColumns['restschuldNachZinsbindungsEnde']" class="selected"><i class="material-icons">check_box</i>Restschuld</div>
                        <div @click="selectColumn('restschuldNachZinsbindungsEnde')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Restschuld</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('darlehensSumme')" v-if="displayColumns['darlehensSumme']" class="selected"><i class="material-icons">check_box</i>Darlehenssumme</div>
                        <div @click="selectColumn('darlehensSumme')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Darlehenssumme</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('bereitstellungszinsfreieZeitInMonaten')" v-if="displayColumns['bereitstellungszinsfreieZeitInMonaten']" class="selected"><i class="material-icons">check_box</i>BZ-freie Zeit</div>
                        <div @click="selectColumn('bereitstellungszinsfreieZeitInMonaten')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>BZ-frei Zeit</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('machbarkeit')" v-if="displayColumns['machbarkeit']" class="selected"><i class="material-icons">check_box</i>Machbarkeit</div>
                        <div @click="selectColumn('machbarkeit')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Machbarkeit</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('optimierungsMeldungen')" v-if="displayColumns['optimierungsMeldungen']" class="selected"><i class="material-icons">check_box</i>Optimierungsmeldung</div>
                        <div @click="selectColumn('optimierungsMeldungen')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Optimierungsmeldung</div>
                    </div>
                    <div class="option">
                        <div @click="selectColumn('annahmeFrist')" v-if="displayColumns['annahmeFrist']" class="selected"><i class="material-icons">check_box</i>Vorschlag gültig bis</div>
                        <div @click="selectColumn('annahmeFrist')" v-else class="unselected"><i class="material-icons">check_box_outline_blank</i>Vorschlag gültig bis</div>
                    </div>
                </div>
                </div>

                <div class="row table-frame">
                    <div class="col">
                        <div>
                            <h3 class="mt-4">Hinweis</h3> <p class="notes">Klicke auf die Spalten für mehr Information.</p>
                            <p v-if="info == 'gesamtRateProMonat'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/monatsrate/" target="_blank">Monatsrate:</a> Es wird versucht möglichst nah an die gewünschte Monatsrate zu gelangen. Sollte die gewünschte Monatsrate zu niedrig angesetzt sein, wird die geringstmögliche Monatsrate gewählt.</p>
                            <p v-if="info == 'effektivZins'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/zins/#3-toc-title" target="_blank">Effektiver Zins:</a> Dies ist der Zins, den der Kreditnehmer im Jahr auf das Darlehen bezahlt.</p>
                            <p v-if="info == 'tilgungssatzInProzent'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/tilgung/#1-toc-title" target="_blank">Anfangstilgung:</a> Der Prozentsatz der Darlehenssumme, den der Kreditnehmen im Jahr an die Bank zurückbezahlt, um die Schuld zu mindern.</p>
                            <p v-if="info == 'steuerErsparnisMonatlichBeiHoechststeuersatz'"><a href="https://www.acrossproperty.com/wiki/steuern/einkommensteuer-bei-immobilien/" target="_blank">Anfängliche Steuerersparnis:</a> Bei vermieteten Objekten lässt sich der Zins bei der Einkommenssteuer als Werbungskosten geltend machen. Als Berechnungsgrundlage wurde ein Steuersatz von 42 % verwendet.</p>
                            <p v-if="info == 'laufzeit'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/kreditlaufzeit/" target="_blank">Laufzeit:</a> Berechnet unter der Annahme, dass der Zins- und Tilgungssatz bis zum Ende der Laufzeit unverändert bleiben.</p>
                            <p v-if="info == 'zinsbindungInJahren'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/zins/#4-toc-title" target="_blank">Zinsbindung:</a> Gibt den Zeitraum an, für den der Zinssatz festgeschrieben wird. Danach muss der Zins mit der Bank neu verhandelt werden.</p>
                            <p v-if="info == 'restschuldNachZinsbindungsEnde'"><a>Restschuld:</a> Die Höhe des übrigen Darlehens am Ende der Zinsbindung.</p>
                            <p v-if="info == 'darlehensSumme'"><a href="https://www.acrossproperty.com/wiki/immobilien-darlehen/kreditsumme/" target="_blank">Darlehenssumme:</a> Darlehenssumme = Kaufpreis + Grunderwerbsteuer (je nach Bundesland) + Notarkosten (1,5 - 2 % je nach Fall) + Courtage (Pauschal 3,57 %) - Eigenkapital</p>
                            <p v-if="info == 'bereitstellungszinsfreieZeitInMonaten'"><a>Bereitstellungszinsfreie Zeit:</a> Zeitraum für den der Darlehensnehmer keinen Bereitstellungszins zahlt, sollte das Darlehen noch nicht verwendet worden sein. Dies ist relevant beim Kauf vom Bauträger.</p>
                            <p v-if="info == 'machbarkeit'"><a>Machbarkeit:</a> Gibt an wie viele Anträge dieser Art genehmigt werden.</p>
                            <p v-if="info == 'optimierungsMeldungen'"><a>Optimierungsmeldung:</a> Erklärt wie die Machbarkeit verbessert werden kann.</p>
                            <p v-if="info == 'annahmeFrist'"><a>Annahmefrist:</a> Datum bis dieses Angebot verfällt.</p>
                            
                        </div>

                        <div style="overflow-x: scroll;">
                            <table>
                                <tr>
                                    <th v-if="displayColumns['bank']" class="bank-head">Kreditinstitut</th>
                                    <th v-if="displayColumns['gesamtRateProMonat']" @click="displayInfo('gesamtRateProMonat')">Monatsrate</th>
                                    <th v-if="displayColumns['effektivZins']" @click="displayInfo('effektivZins')">Eff. Zins</th>
                                    <th v-if="displayColumns['tilgungssatzInProzent']" @click="displayInfo('tilgungssatzInProzent')">Tilgungssatz</th>
                                    <th v-if="displayColumns['steuerErsparnisMonatlichBeiHoechststeuersatz']" @click="displayInfo('steuerErsparnisMonatlichBeiHoechststeuersatz')">Steuerersparnis mtl.*</th>
                                    <th v-if="displayColumns['laufzeit']" @click="displayInfo('laufzeit')">Vorraussichtliche Laufzeit</th>
                                    <th v-if="displayColumns['zinsbindungInJahren']" @click="displayInfo('zinsbindungInJahren')">Zinsbindung</th>
                                    <th v-if="displayColumns['restschuldNachZinsbindungsEnde']" @click="displayInfo('restschuldNachZinsbindungsEnde')">Restschuld <br>nach Zinsbindung</th>
                                    <th v-if="displayColumns['darlehensSumme']" @click="displayInfo('darlehensSumme')">Darlehenssumme</th>
                                    <th v-if="displayColumns['bereitstellungszinsfreieZeitInMonaten']" @click="displayInfo('bereitstellungszinsfreieZeitInMonaten')">BZ-freie Zeit</th>
                                    <th v-if="displayColumns['machbarkeit']" @click="displayInfo('machbarkeit')">Machbarkeit</th>
                                    <th v-if="displayColumns['optimierungsMeldungen']" @click="displayInfo('optimierungsMeldungen')">Optimierungsmeldungen</th>
                                    <th v-if="displayColumns['annahmeFrist']" @click="displayInfo('annahmeFrist')">Vorschlag gültig bis</th>
                                </tr>
                                <tr v-for="(proposal, proposalIndex) in proposals" v-bind:key="proposalIndex" class="proposal">
                                    <td v-if="displayColumns['bank']" class="bank-field">{{ proposal.bank }}</td>
                                    <td v-if="displayColumns['gesamtRateProMonat']">{{ proposal.gesamtRateProMonat.toLocaleString('de') }} €</td>
                                    <td v-if="displayColumns['effektivZins']">{{ proposal.effektivZins.toLocaleString('de') }} %</td>
                                    <td v-if="displayColumns['tilgungssatzInProzent']">{{ proposal.tilgungssatzInProzent.toLocaleString('de') }} %</td>
                                    <td v-if="displayColumns['steuerErsparnisMonatlichBeiHoechststeuersatz']">{{ proposal.steuerErsparnisMonatlichBeiHoechststeuersatz.toLocaleString('de') }} €</td>
                                    <td v-if="displayColumns['laufzeit']">{{ proposal.laufzeit.toLocaleString('de') }} Jahre</td>
                                    <td v-if="displayColumns['zinsbindungInJahren']">{{ proposal.zinsbindungInJahren.toLocaleString('de') }} Jahre</td>
                                    <td v-if="displayColumns['restschuldNachZinsbindungsEnde']">{{ proposal.restschuldNachZinsbindungsEnde.toLocaleString('de') }} €</td>
                                    <td v-if="displayColumns['darlehensSumme']">{{ proposal.darlehensSumme.toLocaleString('de') }} €</td>
                                    <td v-if="displayColumns['bereitstellungszinsfreieZeitInMonaten']">{{ proposal.bereitstellungszinsfreieZeitInMonaten.toLocaleString('de') }} Monate</td>
                                    <td v-if="displayColumns['machbarkeit']">{{ proposal.machbarkeit.toLocaleString('de') }} %</td>
                                    <td v-if="displayColumns['optimierungsMeldungen']">
                                        <p v-for="(meldung, _) in proposal.optimierungsMeldungen.machbarkeit" v-bind:key="_" class="notes">- {{ meldung.text }}</p>
                                    </td>
                                    <td v-if="displayColumns['annahmeFrist']">{{ new Date(proposal.annahmeFrist).toLocaleDateString('de') }}</td>

                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <p class="notes">*Anfängliche Steuerersparnis (monatlich). Kann nur bei Kapitalanlagen geltend gemacht werden.</p>
                <p class="notes">
                    **In dieser Ansicht werden ausschließlich
                    <a href="https://www.acrossproperty.com/wiki/darlehensarten/annuitaetendarlehen/" target="_blank">Annuitäten</a>
                    und
                    <a href="https://www.acrossproperty.com/wiki/darlehensarten/zinszahlungsdarlehen/" target="_blank">Zinszahlungsdarlehen</a>
                    angezeigt. Diese Vorschläge sind nicht verbindlich und können erst nach einer Beratung und erfassung aller relevanten Dokumente bei der jeweiligen Bank angefragt werden. Bei Fragen zu anderen Darlehensarten und Finanzierungsangeboten, melde Dich bitte über das Formular oder telefonisch bei Deinem Finanzierungsberater von Across Property.
                </p>
                <div class="europace">
                    <p>Powered by:</p>
                    <img src="@/assets/europace-Logo.svg" alt="Europace Logo" class="europace-logo">
                </div>
        
            </div>
            <div class="spacer"></div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex';

const store = useStore()
const proposals = computed(() => store.state.financingProposalsMakler)

const loadStateData = computed(() => store.state.loadStateData)

const isOptions = ref(false)

const displayColumns = ref({
    bank: true,
    gesamtRateProMonat: true,
    effektivZins: true,
    tilgungssatzInProzent: false,
    darlehensSumme: false,
    zinsbindungInJahren: false,
    restschuldNachZinsbindungsEnde: false,
    laufzeit: false,
    steuerErsparnisMonatlichBeiHoechststeuersatz: false,
    bereitstellungszinsfreieZeitInMonaten: false,
    machbarkeit: true,
    annahmeFrist: true,
})

const toggleOptions = () => isOptions.value = !isOptions.value
const selectColumn = (col) => displayColumns.value[col] = !displayColumns.value[col]

const info = ref('gesamtRateProMonat')

const displayInfo = (infoField) => {
    info.value = infoField
}
</script>

<style scoped>
.proposal-display {
    min-height: 5rem;
}

h2 {
    color: #3B4868;
    font-weight: 600;
}
h3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    color: #39B3B7;
}
.proposals-outer-box {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 1rem 0.5rem 0 0.5rem;
    padding: 2rem;
}
table {
    width: 100%;
    overflow-x: scroll;
    font-weight: 600;
}

tr {
    border-bottom: 2px solid #C2C9D1;
    height: 100px;
}
.proposal:hover {
    background-color: #eeeeee;
}

th {
    text-align: left;
    color: #3fe395;
    padding-bottom: 1.5rem;
    padding-left: 10px;
    cursor: pointer;
}

td {
    text-align: left;
    min-width: 150px;
    padding-left: 10px;
}

.bank-head {
    padding-bottom: 0;
    color: #3B4868;
}
.bank-field {
    max-width: 80px;
}
.unselected {
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 0;
    display: flex;
    align-items: center;
}
.selected {
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 0;
    display: flex;
    align-items: center;
    color: #3B4868;
}

.options {
    cursor: pointer;
    background-color: #eeeeee;
    border-radius: 0.5rem;
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
    border: none;
    transition: color 250ms, background-color 250ms, box-shadow 250ms;
}
.options:hover {
    /* color: #39B3B7; */
    background-color: #dfdfdf;
    color: #303951;

}
.options-open {
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #dfdfdf;
}
.options-open:hover {
    color: #303951;
    background-color: #eeeeee;
}
.finance-icon {
height: 1.2rem;
padding-right: 0.25rem;
}
.material-icons {
    padding-right: 0.5rem;
}
.spalten-anzeigen {
    font-weight: 600;
    padding-bottom: 1.5rem;
    color: #39B3B7;
}
.dropdown-options {
    background-color: #eeeeee;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    padding: 2rem 3rem 2rem 3rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    -webkit-animation: fadein 200ms; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 200ms; /* Firefox < 16 */
        -ms-animation: fadein 200ms; /* Internet Explorer */
         -o-animation: fadein 200ms; /* Opera < 12.1 */
            animation: fadein 200ms;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.option {
    width: 200px;
}
.spacer {
    padding: 1rem;
}
.europace {
    display: flex;

    align-items: end;
    flex-direction: column;
}
.europace-logo {
    width: 200px;
}
.notes {
    margin-top: 1rem;
    font-style: italic;
    font-size: 0.8rem;
}
</style>