<template>
    <div class="ap-status-bar">
        <div v-for="(status, index) in statuses"
            :key="index"
            class="ap-status-bar--status"
            :class="[size]">
            <div class="ap-status-bar--status--number"
                :class="{
                    'active': isActiveStatus(status),
                    'remaining': isRemainingStatus(index)
                }">
                <div class="ap-status-bar--status--number--index">
                    {{ index + 1 }}
                </div>
            </div>
            <div class="ap-status-bar--status--label" lang="de">
                {{ getStatusLabel(status, index) }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
    value: Object,
    statuses: Array,
    size: {
        type: String,
        default: 'small',
    },
})

const activeStatus = computed(() => props.value)

const isActiveStatus = (status) => activeStatus.value?.id === status.id
const isRemainingStatus = (index) => props.statuses.findIndex(status => isActiveStatus(status)) < index
const getStatusLabel = (status, index) =>
    isActiveStatus(status) || isRemainingStatus(index) ? status.activeLabel : status.label
</script>

<style lang="scss" scoped>
.ap-status-bar {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .small {
        --label-font-size: 0.75rem;
        --number-font-size: 1rem;
        --number-container-size: 2rem;
        --number-index-size: 1.5rem;
    }
}

.ap-status-bar--status {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--number-font-size);
    justify-content: center;
    text-align: center;

    &--number {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: var(--number-container-size);
        width: var(--number-container-size);

        &.active {
            background-color: rgba(63, 227, 149, 20%);
        }

        &.remaining > .ap-status-bar--status--number--index {
            background-color: white;
            color: #3FE395;
        }
    }

    &--number--index {
        background-color: #3FE395;
        border: 0.125rem solid #3FE395;
        border-radius: 50%;
        color: white;
        font-size: var(--number-font-size);
        font-weight: 700;
        height: var(--number-index-size);
        line-height: 1.25;
        width: var(--number-index-size);
    }

    &--label {
        color: #3B4868;
        font-weight: 700;
        font-size: var(--label-font-size);
        hyphens: auto;
        line-height: 1.25;
        max-width: 4rem;
        word-wrap: break-word;
    }
}
</style>