<template>
	<div class="ap-slider" >
		<input
			v-model="sliderValue"
			ref="sliderRef"
			type="range"
			:min="min"
			:max="max"
			:step="step"
			:disabled="disabled"
			:class="[ variant, { invalid } ]"
			@input="onInput()"
			@change="onChange()" />
		<div v-if="showInputField" class="ap-slider--input-field" :class="{ invalid }">
			<input
				v-model="inputFieldValue"
				ref="inputFieldRef"
				type="number"
				@input="onInput()"
				@change="onChange()"
				@blur="onInputBlur()" />
			<div class="ap-slider--input-field--symbol">
				€
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watchEffect } from 'vue'

const props = defineProps({
	modelValue: [Number, String],
	variant: {
		type: String,
		default: 'default',
	},
	min: [Number, String],
	max: [Number, String],
	step: [Number, String],
	disabled: Boolean,
	invalid: Boolean,
	color: String,
	showInputField: Boolean,
})

const min = computed(() => parseInt(props.min))
const max = computed(() => parseInt(props.max))
const step = computed(() => parseInt(props.step))
const sliderRef = ref() 
const inputFieldRef = ref()

const sliderValue = computed({
	get() {
		inputFieldRef.value?.checkValidity()
		return parseInt(props.modelValue) ?? 0
	},
	set(value) {
		// Needed for v-model, see https://vuejs.org/guide/components/v-model.html#v-model-arguments
		emit('update:modelValue', parseInt(value))
	},
})

const inputFieldValue = computed({
	get() {
		return sliderValue.value
	},
	set(value) {
		const numberValue = isNaN(value) ? min.value : value
		const maxBoundValue = numberValue > max.value ? max.value : numberValue
		const maxMinBoundValue = maxBoundValue < min.value ? min.value : maxBoundValue
		sliderValue.value = maxMinBoundValue
		inputFieldRef.value.value = maxMinBoundValue
	}
})

const onInput = () => {
	emit('input', sliderValue.value)
}

const onChange = () => {
	emit('change', sliderValue.value)
}

const onInputBlur = () => {
	const adjustedValue = Math.round(sliderValue.value / step.value) * step.value
	inputFieldValue.value = adjustedValue
}

watchEffect(() => {
	if (props.color) {
		sliderRef.value?.style.setProperty('--accent-color', props.color)
	}
})

const emit = defineEmits(['input', 'change', 'update:modelValue'])
</script>

<style lang="scss" scoped>
.ap-slider {
	align-items: center;
	display: flex;
	gap: 1rem;
	height: 1.5rem;
	max-width: 35rem;
	position: relative;
	width: auto;

	> input[type='range'].default {
		--accent-color: #3FE395;
	}

	> input[type='range'].dark {
		--accent-color: #39B3B7;
	}

	> input[type='range']:not(:focus).invalid {
		--accent-color: #fe5f55;
	}

	> input[type='range'] {
		all: unset;
		background: transparent;
		cursor: pointer;
		height: 100%;
		margin: 0;
		overflow: hidden;
		width: 100%;
	}

	> input[type='range']:focus-visible {
		outline: none;
	}

	@mixin general-styling-thumb {
		background-color: white;
		border: 0.125rem solid var(--accent-color);
		border-radius: 1rem;
		cursor: pointer;
		height: 1rem;
		width: 1rem;
	}

	@mixin general-styling-track {
		background: #D9D9D9;
		border: none;
		border-radius: 0.5rem;
		cursor: pointer;
		height: 0.25rem;
		width: 100%;
	}

	/* Classes for browsers need to be defined separately even if they are the same. */
	
	> input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
		@include general-styling-thumb;

		/* Needed to style the progress range */
		box-shadow: calc(-100vmax - 1rem) 0 0 100vmax var(--accent-color);
		clip-path: polygon(
			100% -0.0625rem,
			0.125rem -0.0625rem,
			0 0.375rem,
			-100vmax 0.375rem,
			-100vmax 0.625rem,
			0 0.625rem,
			0.125rem 100%,
			calc(100% + 1px) calc(100% + 1px)
		);

		/* Only necessary for Chrome, in Firefox and IE it is automatic */
		margin-top: -0.375rem;
	}

	> input[type='range']::-moz-range-thumb {
		@include general-styling-thumb;
	}

	> input[type='range']::-webkit-slider-runnable-track {
		@include general-styling-track;
	}

	> input[type='range']::-moz-range-track {
		@include general-styling-track;
	}

	> input[type='range']::-moz-range-progress {
		appearance: none;
		background-color: var(--accent-color);
	}

	&--input-field {
		display: flex;
		height: 2.625rem;
		max-width: 7.5rem;
		position: relative;

		> input {
			background-color: white;
			border: 0.0625rem #C2C9D1 solid;
			border-radius: 0.5rem;
			box-shadow: 0 0.25rem 0.3125rem rgba(black, 15%);
			color: rgba(black, 0.5);
			font-size: 0.875rem;
			height: 2.5rem;
			margin: 0;
			padding: 0.625rem 1.5rem 0.625rem 0.5rem;
			transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
			width: 100%;
		}

		> input:focus,
		> input:hover {
			box-shadow: inset 0 0 0.125rem 0.125rem rgba(#8f9bba, 0.75);
			color: rgba(black, 0.75)
		}

		> input:focus-visible {
			outline: none;
		}

		// hide arrows from number input for Chrome, Safari, Edge, Opera
		> input::-webkit-inner-spin-button,
		> input::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		// hide arrows from number input for Firefox
		> input {
			-moz-appearance: textfield;
		}

		&--symbol {
			color: rgba(black, 0.5);
			right: 0.5rem;
			top: 0.5rem;
			position: absolute;
		}
	}

	&--input-field:not(:focus-within).invalid {
		> input {
			background-color: #ffebed;
			box-shadow: inset 0 0 0.125rem 0.125rem #fe5f55;
			color: #fe5f55;
		}

		> .ap-slider--input-field--symbol {
			color: #fe5f55;
		}
	}
}
</style>
