import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

export default () => {
    const route = useRoute()
    const companyName = ref(route.query?.companyName)
    const primaryColor = ref(route.query?.primaryColor ?? '#3fe395')
    const secondaryColor = ref(route.query?.secondaryColor ?? '#39b3b7')

    watchEffect(() => {
        document.documentElement.style.setProperty('--primary-color', primaryColor.value)
        document.documentElement.style.setProperty('--secondary-color', secondaryColor.value)
    })

    return {
        companyName,
        primaryColor,
        secondaryColor,
    }
}