<template>
    <div class="deal-tile-header">
        <h4 class="deal-tile-header--customer-name">{{ customerName }}</h4>
        <div class="deal-tile-header--toggle" @click="toggleVisibility()">
            <div class="deal-tile-header--toggle--text">{{ toggleText }}</div>
            <img class="deal-tile-header--toggle--icon" :src="chevronIcon" />
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import { booleanProp, stringProp } from 'vue-ts-types'
import chevronDownIcon from '../../assets/chevron-down.svg'
import chevronUpIcon from '../../assets/chevron-up.svg'

const props = defineProps({
    customerName: stringProp().required,
    isCollapsed: booleanProp().required,
})

const isCollapsed = ref(props.isCollapsed)
const chevronIcon = computed(() => isCollapsed.value ? chevronDownIcon : chevronUpIcon)
const toggleText = computed(() => `${isCollapsed.value ? 'Mehr' : 'Weniger'} Anzeigen`)

const toggleVisibility = () => {
    isCollapsed.value = !isCollapsed.value
    emit('toggle', isCollapsed.value)
}

const emit = defineEmits([
    'toggle'
])
</script>

<style lang="scss" scoped>
.deal-tile-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.deal-tile-header--customer-name {
    color: #39B3B7;
    margin: 0;
}

.deal-tile-header--toggle {
    cursor: pointer;
    display: flex;

    &--text {
        color: #39B3B7;
        line-height: 1.5rem;
    }

    &--icon {
        fill: #39B3B7;
        height: 1.5rem;
        width: 1.5rem;
    }
}
</style>