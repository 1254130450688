<template>
    <div class="solar-financing-finder-contact-dialog-success">
        <h6>Geschafft!</h6>
        <h3>Vielen Dank für deine Anfrage! Wir melden uns innerhalb von 24h bei Dir.</h3>
        <div class="solar-financing-finder-contact-dialog-success--check-icon">
            <img :src="checkIcon" />
        </div>
        <ap-status-bar :value="activeStatus" :statuses="statuses" />
        <div class="solar-financing-finder-contact-dialog-success--close" @click="emit('close')">
            schließen
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import checkIcon from '@/assets/check-icon.svg'

const props = defineProps({
    statuses: Array,
})
const activeStatus = computed(() => props.statuses[2])

const emit = defineEmits(['close'])
</script>

<style lang="scss" scoped>
.solar-financing-finder-contact-dialog-success {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1.5rem;
    text-align: center;

    // TODO: remove this - use ap-icon instead (or import as svg directly - img-tag might not be necessary)
    &--check-icon {
        align-items: center;
        background-color: #3FE395;
        box-shadow: 0 0 0 1rem rgba(63, 227, 149, 20%);
        border-radius: 50%;
        display: flex;
        height: 6.5rem;
        justify-content: center;
        margin: 3rem;
        width: 6.5rem;

        > img {
            // TODO: remove this once moved to clean repo - import svg directly and modify using fill property (or color)
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(295deg) brightness(103%) contrast(101%);
            height: 4rem;
            width: 4rem;
        }
    }

    &--close {
        color: #b6b6b6;
        cursor: pointer;
        padding-top: 2.5rem;
        text-decoration: underline;
    }
}
</style>