<template>
    <div class="input-outer-box">
        <div class="content">
        <h3>Across property</h3>
        <h2 class="mb-3">Schnell, digital & doch persönlich</h2>
        <p class="mb-5">Du möchtest zu Deiner Finanzierung beraten werden? Durch eine Persönliche Beratung erhältst Du in der Regel bessere Finanzierungs- angebote. <br><br>Zudem unterstützen wir Dich mit den erforderlichen Dokumenten und kümmern uns um die Formalitäten mit der Bank. Das Ganze ist für Dich kostenfrei.</p>
        <div class="flex">
            <div>
                <img src="@/assets/makler-nikolas.png" alt="Nikolas Vornehm, ihr Finanzierungsberater" class="makler-img">
                <p  class="mb-2"><b>Nikolas Vornehm</b></p>
                <p class="mb-2">Ihr Finanzierungsberater</p>
                <p>+49 (0) 157 31 66 22 85</p>
            </div>
            <div>
                <img :src="this.$store.state.contact.qrCode" alt="QR-Code" class="qr-img">
                <p class="qr-label">(Mit Smartphone Scannen)</p>
            </div>
        </div>
        <a :href="this.$store.state.contact.link" target="_blank" class="btn button-ap-2 cta-finance-button">Kontakt Aufnehmen</a>
    </div>
    </div>
</template>

<style scoped>
h2 {
    color: #3B4868;
    font-weight: 600;
}
h3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    color: #39B3B7;
}
    .makler-img {
        max-width: 10rem;
    }
    .qr-img {
        max-width: 8rem;
        border: 0px solid #39B3B7;
        border-radius: 0.5rem;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    }
    .input-outer-box {
    background-color: #F8FBFE;
    border-radius: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 1rem 2rem 0 2rem;
    padding: 2rem;

    }
    
    .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .qr-label {
        margin-top: 0.5rem;
        font-style: italic;
        font-size: 0.8rem;
        color: #39B3B7;
        font-weight: 600;
    }
    .cta-finance-button {
        margin: auto;
        margin-top: 2rem;
        display: block;
        max-width: 300px;
    }
    .content {
        margin:auto;
        max-width: 500px;
    }
</style>

