export const conditions = [
    'ALLE',
    'Altbau',
    'Entkernt',
    'Erstbezug',
    'Neuwertig',
    'Neubau',
    'Projektiert',
    'Rohbau',
    'Renovierungsbedürftig',
    'Renoviert',
    'Saniert',
]