<template>
    <ap-tile class="deal-tile" width="full-width">
        <deal-tile-header :customer-name="props.deal?.name" :is-collapsed="isCollapsed" @toggle="toggleVisibility($event)" />
        <div v-if="!isCollapsed" class="deal-tile--content">
            <deal-tile-general-details
                :commission-agent="deal.commissionAgent"
                :commission-ap="deal.commissionAp"
                :partner-commission-in-percent="deal.partnerCommissionInPercent"
                :close-date="deal.closeDate"
                :pay-date="deal.payDate"
                :success-probability="deal.successProbability" />
            <div class="deal-tile--content--statuses">
                <h6 class="font-weight-600">Status</h6>
                <div class="deal-tile--content--statuses--stages">
                    <ap-tile 
                        v-if="isDealLost"
                        class="failed-status"
                        :class="getIsSelected(currentStage.id)"
                        size="small"
                        @click="selectPipelineStage(currentStage)">
                        {{ currentStage.title }}
                    </ap-tile>
                    <ap-tile 
                        v-for="phase in displayPipelineStages"
                        :key="phase.id"
                        :class="getStyleForStatus(phase.id)"
                        size="small"
                        @click="selectPipelineStage(phase)">
                        {{ phase.title }}
                    </ap-tile>
                </div>
                <deal-status-stages
                    :selected-status="selectedPipelineStage"
                    :deal-stage="deal.dealStage"
                    :closed-lost-reason="deal.closedLostReason" />
            </div>
            <deal-contacts :contacts="deal.contacts" />
        </div>
    </ap-tile>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import DealStatusStages from './DealStatusStages.vue'
import DealTileHeader from './DealTileHeader.vue'
import DealTileGeneralDetails from './DealTileGeneralDetails.vue'
import DealContacts from './DealContacts.vue'

const store = useStore()

const props = defineProps({
    deal: Object,
})

const isCollapsed = ref(false)
const pipelineStages = computed(() => store.state.pipelineStages)
const displayPipelineStages = computed(() => pipelineStages.value.filter(stage => stage.id !== 'phase-lost'))
const currentStageIndex = computed(() => pipelineStages.value.findIndex(
    phase => {
        const stageIds = phase.stages.map(stage => stage.id)
        return stageIds?.includes(props.deal.dealStage)
    }))
const currentStage = computed(() => pipelineStages.value[currentStageIndex.value])
const isDealLost = computed(() => currentStage.value.id === 'phase-lost')
const selectedPipelineStage = ref(currentStage.value)

const selectPipelineStage = (status) => {
    selectedPipelineStage.value = status
}

const getStyleForStatus = (statusId) => {
    const selected = getIsSelected(statusId)
    const status = getStatus(statusId)
    return `${selected} ${status}`
}

const toggleVisibility = (newState) => {
    isCollapsed.value = newState
}

const getStatus = (stageId) => {
    const index = pipelineStages.value.findIndex(stage => stage.id === stageId)
    if (isDealLost.value || index > currentStageIndex.value) {
        return 'remaining-status'
    }
    if (index === currentStageIndex.value) {
        return 'current-status'
    }
    return 'passed-status'
}

const getIsSelected = (stageId) => selectedPipelineStage.value?.id === stageId ? 'is-selected' : 'is-not-selected'
</script>

<style lang="scss" scoped>
.deal-tile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.deal-tile--content{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.deal-tile--content--statuses {
    h6 {
        margin: 0;
    }

    &--stages {
        display: flex;
        gap: 1rem;
        padding: 0.5rem 0 1rem;

        > .current-status {
            background-color: #FFD130 !important;
        }

        > .passed-status {
            background-color: rgba(#3FE395, 60%) !important;
            color: white !important;
        }

        > .remaining-status {
            background-color: #EDEDED !important;
            color: #878787 !important;
        }

        > .failed-status {
            background-color: #F23985 !important;
            color: white !important;
        }

        > .is-selected {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.75);
        }

        > .is-not-selected {
            cursor: pointer;
        }
    }
}
</style>