<template>
    <div class="row my-3 mx-5">

        <div class="col-auto mx-3" v-if="this.$store.state.inputOptions.communities">
            <div class="mb-1">Wonach möchstest Du suchen?</div>
            <div class="mb-3">
                <span v-if="this.$store.state.geoSearchCat == 'community'" class="mx-2 selected col-auto">Landkreis</span>
                <span v-else @click="this.$store.commit('setGeoSearchCat', 'community')" class="mx-2 unselected col-auto">Landkreis</span>
                <span v-if="this.$store.state.geoSearchCat == 'city'" class="mx-2 selected col-auto">Stadt / Gemeinde</span>
                <span v-else @click="this.$store.commit('setGeoSearchCat', 'city')" class="mx-2 unselected col-auto">Stadt / Gemeinde</span>
                <span v-if="this.$store.state.geoSearchCat == 'postcode'" class="mx-2 selected col-auto">Postleitzahl</span>
                <span v-else @click="this.$store.commit('setGeoSearchCat', 'postcode')" class="mx-2 unselected col-auto">Postleitzahl</span>
            </div>
            <div class="row">
                <div class="col" v-if="this.$store.state.geoSearchCat == 'community'">
                    <div><input class="small-text" type="text" name="" id="community-input" v-model="this.$store.state.community" list="communities-list"></div>
                    <div><label class="small-text" for="community-input">Landkreis</label></div>
                    <div class="warning" v-if="!this.$store.state.inputOptions.communities.includes(this.$store.state.community)">Bitte einen der Vorschläge auswählen</div>
                    <datalist id="communities-list">
                        <div v-for="(option, index) in this.$store.state.inputOptions.communities" :key="index">
                            <option :value="option" />
                        </div>
                    </datalist>
                </div>

                <div class="col" v-if="this.$store.state.geoSearchCat == 'city'">
                    <div><input class="small-text" type="text" name="" id="city-input" v-model="this.$store.state.city" list="cities-list"></div>
                    <div><label class="small-text" for="city-input">Stadt / Gemeinde</label></div>
                    <div class="warning" v-if="!cities.includes(this.$store.state.city)">Bitte einen der Vorschläge auswählen</div>
                    <datalist id="cities-list">
                        <div v-for="(option, index) in cities" :key="index">
                            <option :value="option" />
                        </div>
                    </datalist>
                </div>
                <div class="col" v-if="this.$store.state.geoSearchCat == 'postcode'">
                    <div><input class="small-text" type="text" name="" id="postcode-input" v-model="this.$store.state.postcode" list="postcode-list"></div>               
                    <div><label class="small-text" for="postcode-input">PLZ</label></div>
                    <div class="warning" v-if="!this.$store.state.inputOptions.postcodes.includes(this.$store.state.postcode)">PLZ existert nicht</div>
                    <datalist id="postcode-list">
                        <div v-for="(option, index) in this.$store.state.inputOptions.postcodes" :key="index">
                            <option :value="option" />
                        </div>
                    </datalist>
                </div>
            </div>
        </div>
        <div class="col-auto mx-3">
            <div class="row small-text mb-3">Immobilientyp</div>
            <select name="" id="property-type" class="small-text" v-model="this.$store.state.propertyType">
                <option value="Eigentumswohnung">Eigentumswohnung</option>
                <option value="Haus">Haus</option>
            </select>
            <div><label class="very-small-text" for="property-type">Typ wählen</label></div>
        </div>
        <div class="col-auto mx-3">
            <div class="row small-text mb-3">Zimmer</div>
            <div class="row">
                <div class="col">
                    <div><input class="small-text" type="number" name="" id="rooms-min" min="1" :max="this.$store.state.roomsMax" v-model="this.$store.state.roomsMin"></div>
                    <div><label class="small-text" for="rooms-min">min.</label></div>
                </div>
                <div class="col">
                    <div><input class="small-text" type="number" name="" id="rooms-max" :min="this.$store.state.roomsMin" max="20" v-model="this.$store.state.roomsMax"></div>
                    <div><label class="small-text" for="rooms-max">max.</label></div>
                </div>
            </div>
        </div>
        <div class="col-auto mx-3">
            <div class="row small-text mb-3">Baujahr</div>
            <div class="row">
                <div class="col">
                    <div><input class="small-text" type="number" name="" id="construction-year-min" :min="1900" :max="this.$store.state.constructionYearMax" v-model="this.$store.state.constructionYearMin"></div>
                    <div><label class="small-text" for="construction-year-min">min.</label></div>
                </div>
                <div class="col">
                    <div><input class="small-text" type="number" name="" id="construction-year-max" :min="this.$store.state.constructionYearMin" :max="2025" v-model="this.$store.state.constructionYearMax"></div>
                    <div><label class="small-text" for="construction-year-max">max.</label></div>
                </div>
            </div>
        </div>
        <div class="col-auto mx-3">
            <div class="row small-text mb-3">Zustand</div>
            <select name="" id="condition" class="small-text" v-model="this.$store.state.condition">
                <option v-for="(option, index) in conditions" :key="index" :value="option">
                    {{ option }}
                </option>
            </select>
            <div><label class="very-small-text" for="condition">Zustand wählen</label></div>
        </div>
        <div class="col-auto mx-3">
            <div class="row small-text mb-3">Zeitraum</div>
            <select name="" id="timeframe" class="small-text" v-model="this.$store.state.timeframe">
                <option value="oneMonth">1 Monat</option>
                <option value="threeMonths">3 Monate</option>
                <option value="sixMonths">6 Monate</option>
            </select>
            <div><label class="very-small-text" for="timeframe">Zeitraum wählen</label></div>
        </div>
        <button class="col-auto m-5 btn button-ap-1" @click="this.$store.dispatch('getData')">Daten laden</button>
    </div>

</template>

<script>
import { onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex'
import { cities } from '../../assets/js/cities.js'
import { conditions } from '../../assets/js/conditions.js'

export default {
    name: 'FilterInputs',

    setup() {
        const store = useStore()
        onMounted(() => {
            store.dispatch('getInputOptions')
        })

        const filterData = computed(() => {
            return {
                // geoSearchCat: store.state.geoSearchCat,
                // community: store.state.community,
                // city: store.state.city,
                // postcode: store.state.postcode,
                // condition: store.state.condition,
                // propertyType: store.state.propertyType,
                timeframe: store.state.timeframe,
            }
        })

        watch (filterData, () => {
            store.dispatch('getData')
        })

        return {
            cities,
            conditions,
            // postcode
        }
    },
}

</script>

