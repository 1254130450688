<template>
    <div class="solar-financing-finder-header">
		<h6 v-if="isEmbedded" class="solar-financing-finder-header--powered-by">
			Powered by <a :href="landingpageLink" target="_blank">Across Property</a>
		</h6>
		<template v-else>
			<img :src="userCompanyLogo" class="solar-financing-finder-header--company-logo" />
			<img :src="houseWithSunCollectorsImage" class="solar-financing-finder-header--house-image">
		</template>
		<h2 class="solar-financing-finder-header--title">
			Zum Finanzierungsangebot für Ihre
			<span class="solar-financing-finder-header--title--highlite-text">Solaranlage </span>
			in nur
			<span class="solar-financing-finder-header--title--highlite-text"> 2 Minuten </span>
		</h2>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import userCompanyLogo from '@/assets/ap-logo-2023.png'
import houseWithSunCollectorsImage from '@/assets/images/house-with-sun-collectors.png'
import externalAssets from '@/assets/external-assets'

const route = useRoute()
const isEmbedded = computed(() => route.path.includes('embed'))
const landingpageLink = computed(() => externalAssets.links.acrossProperty.landingPage)
</script>

<style lang="scss" scoped>
.solar-financing-finder-header {
    color: #3B4868;
	display: flex;
	flex-direction: column;
	gap: 3rem;
    position: relative;
	text-align: center;
}

.solar-financing-finder-header--powered-by {
	color: #C2C9D1;
	text-align: right;
}

.solar-financing-finder-header--company-logo {
	width: 16rem;
}

.solar-financing-finder-header--house-image {
	right: -2rem;
	top: -2rem;
	position: absolute;
}

.solar-financing-finder-header--title {
	font-weight: 700;
	margin: 0 auto;
	width: 40%;

    &--highlite-text {
        color: var(--primary-color);
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
}

@media (max-width: 1200px) {
	.solar-financing-finder-header--title {
		width: 60%;
	}
}

@media (max-width: 980px) {
	.solar-financing-finder-header--house-image {
		right: -1.9rem;
		top: -1.5rem;
		width: 11rem;
	}	
}

@media (max-width: 680px) {
	.solar-financing-finder-header {
		
		&--company-logo { 
			margin: 0 auto;
		}

		&--house-image {
			display: none;
		}

		&--title {
			width: 100%;
		}
	}
}
</style>