<template>
    <ap-dialog class="solar-financing-finder-contact-dialog" show-close-button @close="emit('close')">
        <template #default>
            <solar-financing-finder-contact-dialog-success
                v-if="hasVerifiedEmail"
                :statuses="statuses"
                @close="emit('close')" />
            <solar-financing-finder-contact-dialog-verify-email
                v-else-if="hasSentRequest"
                :statuses="statuses" />
            <solar-financing-finder-contact-dialog-form
                v-else
                :show-error-message="showErrorMessage"
                @send-request="sendRequest($event)" />
        </template>
    </ap-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import SolarFinancingFinderContactDialogForm from './SolarFinancingFinderContactDialogForm.vue'
import SolarFinancingFinderContactDialogVerifyEmail from './SolarFinancingFinderContactDialogVerifyEmail.vue'
import SolarFinancingFinderContactDialogSuccess from './SolarFinancingFinderContactDialogSuccess.vue'
import { provide } from 'vue'
import { SolarFinderKey } from '@/components/solar-financing-finder/injection-keys'

const props = defineProps({
    solarFinderHelper: Object,
    financingRequest: Object,
    hasVerifiedEmail: {
        type: Boolean,
        default: false,
    },
    sendRequestFunction: Function,
    verifyEmailFunction: Function,
})

const hasSentRequest = ref(false)
const showErrorMessage = ref(false)
const statuses = ref([
    {
        id: 1,
        label: 'Formular ausgefüllt',
        activeLabel: 'Formular ausfüllen',
    },
    {
        id: 2,
        label: 'E-Mail bestätigt',
        activeLabel: 'E-Mail bestätigen',
    },
    {
        id: 3,
        label: 'Angebot erhalten',
        activeLabel: 'Angebot erhalten',
    },
])

const sendRequest = async (contact) => {
    if (props.sendRequestFunction) {
        try {
            const payload = {
                financingRequest: props.financingRequest,
                contact,
            }
            await props.sendRequestFunction(payload)
            hasSentRequest.value = true
        } catch (error) {
            showErrorMessage.value = true
        }
    }
}

provide(SolarFinderKey, props.solarFinderHelper)

const emit = defineEmits(['close'])
</script>

<!-- TODO: make style scoped - only now to avoid repeating styles across subcomponents -->
<style lang="scss">
.solar-financing-finder-contact-dialog {
    h3 {
        font-weight: 600;
    }
    
    // TODO: remove once headers are standarized
    h5 {
        color: #3B4868;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }

    h6 {
        color: #39B3B7;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
    }

    // TODO: remove once paragraphs are standarized
    p {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
        margin: 0;
        text-align: center;
    }
}
</style>