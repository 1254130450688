<template>
    <div id="login-page-bg">
        <div class="login-form-box email-confirmed">
            <img class="ap-logo-login mb-5" src="../../assets/ap-logo.png" alt="AP Logo">
            <div>
                <div v-if="isLoading">
                    <div>
                        Bitte warten...
                    </div>
                </div>
                <div v-else-if="verificationStatus == 1">
                    <h3>
                        Deine E-Mail ist bestätigt
                    </h3>
                    Du solltest nun Deine Zugangsdaten in einer weiteren E-Mail erhalten haben. Falls nicht, schicke uns doch bitte eine Email an:
                    <a :href="mailToInfo">{{ apInfoEmail }}</a>
                </div>
                <div v-else-if="verificationStatus == 2">
                    <h3>
                        Deine E-Mail ist bestätigt
                    </h3>
                    Dein Berater wird sich innerhalb von 24 Stunden (nur werktags) bei Dir melden.
                </div>
                <div v-else>
                    Hoppla... Es scheint ein Fehler aufgetreten zu sein.
                    <br>
                    Hast Du diese Email Adresse vielleicht schon verifiziert?
                    <br><br>
                    Falls nicht, versuche es bitte erneut.
                    <br><br>
                    Sollte der Fehler nochmal passieren, schreibe uns doch
                    <br>
                    bitte eine Nachricht:
                    <a :href="mailToInfo">{{ apInfoEmail }}</a>
                </div>
                <router-link v-if="showLinkToLogin" to="/login" class="btn button-ap-1 mt-5">
                    Zum Login
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import externalAssets from '@/assets/external-assets'

const store = useStore()
const route = useRoute()
const isLoading = ref(false)
const verificationStatus = ref()
const apInfoEmail = computed(() => externalAssets.emails.acrossProperty.info)
const mailToInfo = computed(() => `mailto:${apInfoEmail.value}`)
const showLinkToLogin = computed(() => verificationStatus.value !== 2)

onMounted(async () => {
    isLoading.value = true
    verificationStatus.value = await store.dispatch('verifyEmail', route.params)
    isLoading.value = false
})
</script>

<style>
.email-confirmed {
    text-align: center;
}
</style>