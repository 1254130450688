import { createWebHistory, createRouter } from 'vue-router'
import LoginView from '../components/LoginView.vue'
import FinanzierungsViewMakler from '../components/FinanzierungsViewMakler.vue'
import KundenView from '../components/KundenView.vue'
import DashboardView from '../components/DashboardView.vue'
import NextSale from '../components/NextSale.vue'
import FinancingRequestView from '../components/FinancingRequestView.vue'
import FinancingRequestVerification from '../components/FinancingRequest/FinancingRequestVerification.vue'
import store from '../store'
import SolarFinancingFinderView from '../components/SolarFinancingFinderView.vue'
import SolarFinancingFinder from '../components/solar-financing-finder/components/SolarFinancingFinder.vue'
import SolarFinancingFinderEmbed from '../components/solar-financing-finder-embed/components/SolarFinancingFinderEmbed.vue'
import ExposeConfirmationPage from '../components/expose/ExposeConfirmationPage.vue'

const routes = [
	{
		path: '/',
		redirect: { name: 'financing' },
	},
	{
		name: 'financing',
		path: '/financing',
		component: FinanzierungsViewMakler,
		meta: { requiresAuth: true },
	},
	{
		name: 'customers',
		path: '/customers',
		component: KundenView,
		meta: { requiresAuth: true },
	},
	{
		name: 'market',
		path: '/market',
		component: DashboardView,
		meta: { requiresAuth: true },
	},
	{
		name: 'next-sale',
		path: '/next-sale',
		component: NextSale,
		meta: { requiresAuth: true },
	},
	{
		name: 'login',
		path: '/login',
		component: LoginView,
	},
	{
		path: '/financing-request',
		children: [
			{
				name: 'financing-request-contact-form',
				path: ':companyId/:partnerId',
				component: FinancingRequestView,
			},
			{
				path: 'email-verification/:contactId/:partnerId',
				name: 'financing-request-verification',
				component: FinancingRequestVerification,
			},
		]
	},
	{
		path: '/solar-financing',
		children: [
			{
				path: 'finder',
				children: [
					{
						name: 'solar-financing-finder',
						path: ':companyId?',
						component: SolarFinancingFinderView,
					},
					{
						name: 'solar-financing-finder-embed',
						path: 'embed/:companyId?',
						component: SolarFinancingFinder,
					},
					{
						name: 'solar-embed-settings',
						path: 'embed/settings',
						component: SolarFinancingFinderEmbed,
						meta: { requiresAuth: true },
					}
				]
			}
		]
	},
	{
		name: 'opt-in-verification',
		path: '/opt-in/:leadId',
		component: ExposeConfirmationPage,
	},
	{
		// Matches all unmatched routes and redirects to login page.
		path: '/:pathMatch(.*)*',
		redirect: { name: 'login' },
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach(async (to, from) => {
	if (!store.state.isAuthenticated) {
		await store.dispatch('getSession')
	}
	const isAuthenticated = store.state.isAuthenticated

	if (isAuthenticated) {
		const canAccess = await store.dispatch('canUserAccess', to.name)

		if (to.meta.requiresAuth && !canAccess) {
			return from.name ?? '/'
		}
		
		if (to.name === 'login') {
			return from.name ?? '/'
		}
	}

	if (!isAuthenticated && to.meta.requiresAuth) {
		return 'login'
	}
})

export default router
