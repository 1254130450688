<template>
    <div class="ap-color-picker">
        <label :for="id">
            {{ label }}
        </label>
        <input
            v-model="colorValue"
            :id="id"
            type="color" />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import generateRandomId from '../helpers/generate-random-id'

const props = defineProps({
	modelValue: [Number, String],
	label: String,
	disabled: Boolean,
})

const id = generateRandomId()
const colorValue = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		// Needed for v-model, see https://vuejs.org/guide/components/v-model.html#v-model-arguments
		emit('update:modelValue', value)
	},
})

const emit = defineEmits(['update:modelValue'])
</script>

<style lang="scss" scoped>
.ap-color-picker {
    align-items: center;
    display: flex;

    > label {
        font-size: 1rem;
		font-weight: 500;
		line-height: 1.25;
		padding-bottom: 0.25rem;
        width: 100%;
    }
}
</style>