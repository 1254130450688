<template>
    <div class="expose-confirmation-page">
        <div class="expose-confirmation-page--content">
            <h6>Geschafft!</h6>
            <h3>Eine E-Mail mit Ihrem Expose wurde jetzt verschickt und liegt in Ihrem Postfach für Sie bereit.</h3>
            <div class="expose-confirmation-page--content--check-icon">
                <img :src="checkIcon" />
            </div>
            <ap-status-bar :value="activeStatus" :statuses="statuses" />
            <div class="expose-confirmation-page--content--additional-info">
                Sollten Sie innerhalb von 15 Minuten keine E-Mail erhalten, melden Sie sich bei uns per E-Mail an
                <a
                    href="mailto:nikolas.vornehm@acrossproperty.com?subject=E-Mail bestätigt, aber kein Exposé erhalten"
                    target="_blank">
                        nikolas.vornehm@acrossproperty.com
                </a>.
                <br>
                <br>
                Sie können dieses Fenster jetzt schließen.
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import checkIcon from '@/assets/check-icon.svg'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

const statuses = ref([
    {
        id: 1,
        label: 'Formular ausgefüllt',
        activeLabel: 'Formular ausfüllen',
    },
    {
        id: 2,
        label: 'E-Mail bestätigt',
        activeLabel: 'E-Mail bestätigen',
    },
    {
        id: 3,
        label: 'Angebot erhalten',
        activeLabel: 'Angebot erhalten',
    },
])
const activeStatus = computed(() => statuses.value[2])

onMounted(async () => {
    const leadId = route.params['leadId']
    await store.dispatch('sendOptInVerification', leadId)
})
</script>

<style lang="scss" scoped>
.expose-confirmation-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #39B3B7, #3FE396, #1ec6cc, #3fd38e);
    background-size: 200% 200%;
    animation: gradient 12s ease infinite;
}

.expose-confirmation-page--content {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1.5rem;
    border-radius: 0.75rem;
    box-shadow:
        0 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 10%),
        0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 40%);
    max-width: 40rem;
    padding: 2.5rem;
    position: relative;
    text-align: center;

    h3 {
        font-weight: 600;
    }

    h6 {
        color: #39B3B7;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
    }

    .ap-status-bar {
        max-width: 20rem;
    }

    &--check-icon {
        align-items: center;
        background-color: #3FE395;
        box-shadow: 0 0 0 1rem rgba(63, 227, 149, 20%);
        border-radius: 50%;
        display: flex;
        height: 6.5rem;
        justify-content: center;
        margin: 3rem;
        width: 6.5rem;

        > img {
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(295deg) brightness(103%) contrast(101%);
            height: 4rem;
            width: 4rem;
        }
    }

    &--additional-info {
        color: #b6b6b6;
        padding-top: 2.5rem;
    }
}
</style>