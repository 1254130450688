<template>
    <div class="ap-copy-button">
        <ap-input
            v-model="inputValue"
            type="text"
            :label="label"
            :disabled="true" />
        <button class="ap-copy-button--button" @click="copyContentToClipboard()">
            <div class="ap-copy-button--button--label" :class="isCopiedLabelStyle">
                Kopieren
            </div>
            <img :src="copyIcon" class="ap-copy-button--button--icon" />
        </button>
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import copyIcon from '../../assets/copy-icon.svg'
import copyToClipboard from '../helpers/copy-to-clipboard'

const props = defineProps({
	modelValue: [Number, String],
    label: String,
})

const inputValue = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		// Needed for v-model, see https://vuejs.org/guide/components/v-model.html#v-model-arguments
		emit('update:modelValue', value)
	},
})

const isCopied = ref(false)
const isCopiedLabelStyle = computed(() => isCopied.value ? 'copied' : undefined)

const copyContentToClipboard = () => {
    copyToClipboard(inputValue.value)
    isCopied.value = true
    setTimeout(() => {
        isCopied.value = false
    }, 3000)
}

const emit = defineEmits(['update:modelValue'])
</script>

<style lang="scss" scoped>
.ap-copy-button {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    position: relative;

    > ::v-deep .ap-input >  input {
        padding-right: 6rem;
    }

    &--button {
        align-items: center;
        background-color: transparent;
        border: none;
        bottom: 0.625rem;
        cursor: pointer;
        display: flex;
        gap: 0.25rem;
        right: 0.5rem;
        position: absolute;
    }

    &--button--label {
        font-size: 0.75rem;
    }

    &--button--label.copied {
        color: #3FE395;
    }

    &--button--icon {
        height: 1rem;
        width: 1rem;
    }
}
</style>