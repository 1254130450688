<template>
    <div>
        <nav class="navbar fixed-top navbar-expand-lg bg-light">
            <div class="container">
                <img class="ap-logo" src="../../assets/ap-logo-2023.png" alt="AP Logo">
                <template v-if="isAuthenticated">
                    <router-link
                        :class="checkIsSelected('financing')"
                        to="/financing">
                        Finanzierung
                    </router-link>
                    <router-link
                        v-if="canAccessCustomers"
                        :class="checkIsSelected('customers')"
                        to="/customers">
                        Kunden
                    </router-link>
                    <router-link
                        v-if="canAccessMarket"
                        :class="checkIsSelected('market')"
                        to="/market">
                        Markt
                    </router-link>
                    <router-link
                        v-if="canAccessSolar"
                        :class="checkIsSelected('solar-embed-settings')"
                        to="/solar-financing/finder/embed/settings">
                        Solar Finanzierung
                    </router-link>
                    <router-link
                        v-if="canAccessNextSale"
                        :class="checkIsSelected('next-sale')"
                        to="/next-sale">
                        Next Sale
                    </router-link>
                    <router-link
                        @click="logout()"
                        to="/login" >
                        Logout
                    </router-link>
                </template>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import router from '@/router'

const store = useStore()
const route = useRoute()
const isAuthenticated = computed(() => store.state.isAuthenticated)
const canAccessCustomers = computed(() => store.getters.canAccessCustomers)
const canAccessMarket = computed(() => store.getters.canAccessMarket)
const canAccessNextSale = computed(() => store.getters.canAccessNextSale)
const canAccessSolar = computed(() => store.getters.canAccessSolar)

const checkIsSelected = (view) => route?.name === view ? 'selected-view' : undefined

const logout = async () => {
    store.commit('setAxiosConfig', '')
    store.commit('setIsAuthenticated', false)
    store.commit('setUsername', '')
    store.commit('setPassword', '')
    await router.push('/login')
}
</script>

<style>
.selected-view {
    font-weight: bold;
}

.navbar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.ap-logo {
    width: 220px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.account-avatar {
    width: 40px;
    height: 40px;
    margin: 0 5px;
}
</style>
