<template>
    <div class="row">
        <div class="col-2" style="
        height:80vh;
        overflow-y:auto;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        background-color: #39b3b7;
        ">
            <div class="row mt-3">
                <div>Gesamtmege Beobachtungen:</div>
                <div>{{ numberOfRecordsPurchase }} Kaufinserate</div>
                <div>{{ numberOfRecordsRent }} Mietinserate</div>
            </div>
            <div class="row">
                <side-nav-dashboard />
            </div>
        </div>
        <div class="col-10">
            <div class="row">
                <filter-inputs />
            </div>
            
            <div class="row" v-if="isReturnView">
                <return-view />
            </div>

            <div class="row" v-if="isMarketView">
                <market-view />
            </div>

            <div class="row" v-if="isRoomsView">
                <rooms-view />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

import FilterInputs from './Dashboard/FilterInputs.vue'
import SideNavDashboard from './Dashboard/SideNav.vue'

import ReturnView from './Dashboard/ReturnView.vue'
import MarketView from './Dashboard/MarketView.vue'
import RoomsView from './Dashboard/RoomsView.vue'


export default {
    name: "DashboardView",

    components: {
        FilterInputs,
        SideNavDashboard,

        ReturnView,
        MarketView,
        RoomsView,
    },

    setup() {
        const store = useStore()

        const numberOfRecordsPurchase = computed(() => store.state.chartsData.totalNumberOfRecords?.purchase)
        const numberOfRecordsRent = computed(() => store.state.chartsData.totalNumberOfRecords?.rent)
        const isReturnView = computed(() => store.state.dashboardView == 'ReturnView')
        const isMarketView = computed(() => store.state.dashboardView == 'MarketView')
        const isRoomsView = computed(() => store.state.dashboardView == 'RoomsView')

        onMounted(() => {
            store.dispatch('getData')
        })

        return {
            numberOfRecordsPurchase,
            numberOfRecordsRent,
            isReturnView,
            isMarketView,
            isRoomsView,
        }
    }
}
</script>

<style scoped>
</style>