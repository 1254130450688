import { computed, ref } from "vue"

export default (companyId, companyName) => {
    const displayCompanyName = ref(companyName)
    const primaryColor = ref('#3FE395')
    const secondaryColor = ref('#39B3B7')
    const adjustedCompanyName = computed(() => encodeURIComponent(displayCompanyName.value))
    const adjustedPrimaryColor = computed(() => primaryColor.value.replace('#', '%23'))
    const adjustedSecondaryColor = computed(() => secondaryColor.value.replace('#', '%23'))

    const companyNameQuery = computed(() => displayCompanyName.value ? `companyName=${adjustedCompanyName.value}` : undefined)
    const primaryColorQuery = computed(() => `primaryColor=${adjustedPrimaryColor.value}`)
    const secondaryColorQuery = computed(() => `secondaryColor=${adjustedSecondaryColor.value}`)
    const query = computed(() => [companyNameQuery?.value, primaryColorQuery.value, secondaryColorQuery.value].filter(Boolean).join('&'))
    const embedLink = computed(() => `https://app.acrossproperty.com/solar-financing/finder/embed/${companyId}?${query.value}`)
    const shareLink = computed(() => `https://app.acrossproperty.com/solar-financing/finder/${companyId}`)

    const codeSnippet = computed(() =>
`<iframe
 id="AcrossPropertySolarFinancingFinder"
 title="Across Property Solar Financing Finder"
 width="100%"
 height="900px"
 allow="fullscreen"
 src="${embedLink.value}"></iframe>`)

    return {
        displayCompanyName,
        primaryColor,
        secondaryColor,
        codeSnippet,
        shareLink,
    }
}