<template>
    <div class="solar-financing-finder-main-form">
        <div class="solar-financing-finder-main-form--content-box">
            <div>
                <h6>Kaufpreis Solaranlage</h6>
                <ap-slider
                    v-model="purchasePrice"
                    min="0"
                    :max="maxPurchasePrice"
                    step="100"
                    :color="primaryColor"
                    show-input-field
                    @input="updatePurchasePrice($event)" />
            </div>
            <div>
                <h6>Gewünschte Laufzeit</h6>
                <ap-dropdown
                    :value="expectedLoanDuration"
                    :options="loanDurationOptions"
                    limit-max-size="3"
                    @select="selectExpectedLoanDurationInYears($event)" />
            </div>
        </div>
        <div class="solar-financing-finder-main-form--content-box">
            <div>
                <h6>Eigenkapital</h6>
                <ap-slider
                    v-model="capital"
                    variant="dark"
                    min="0"
                    :max="purchasePrice"
                    step="100"
                    :color="secondaryColor"
                    show-input-field />
            </div>
            <div>
                <h6>kWpeak (in kWp)</h6>
                <ap-input v-model="maxOutput" type="number" @input="updateMaxOutput()" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { SolarFinderKey } from '../injection-keys'

const store = useStore()
const {
    primaryColor,
    secondaryColor
} = inject(SolarFinderKey)

const hasLandCharge = computed(() => store.state.hasLandCharge)
const maxPurchasePrice = computed(() => hasLandCharge.value ? 1000000 : 100000)
const loanDurationOptions = computed(() => new Array(24).fill(2)
    .map((offset, index) => {
        const years = offset + index
        return {
            id: years,
            title: `${years} Jahre`,
            value: years
        }
    }))
const purchasePrice = ref(20000)
const capital = ref(0)
const expectedLoanDuration = computed(() => store.state.expectedLoanDuration)
const maxOutput = ref(8)

const updatePurchasePrice = () => store.commit('updatePurchasePrice', purchasePrice.value)
const selectExpectedLoanDurationInYears = (option) => store.commit('updateLoanDuration', option)
const updateMaxOutput = () => store.commit('updateMaxOutput', maxOutput.value)

watchEffect(() => {
    capital.value = capital.value > purchasePrice.value ? purchasePrice.value : capital.value
    store.state.capital = capital.value > purchasePrice.value ? purchasePrice.value : capital.value
})
</script>

<style lang="scss" scoped>
.solar-financing-finder-main-form {
    display: flex;
    gap: 2rem;
}

.solar-financing-finder-main-form--content-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

@media (max-width: 680px) {
    .solar-financing-finder-main-form {
        flex-wrap: wrap;

        &--content-box:nth-of-type(1) {
            flex-direction: column-reverse;
        }
    }
}
</style>