<template>
    <component :is="renderedContainerElement" class="solar-financing-finder" size="full-width">
        <solar-financing-finder-header />
        <div
            class="solar-financing-finder--body"
            :class="{ 'padding-bottom': !showOffer }">
            <solar-financing-finder-main-form />
            <hr class="solar-financing-finder--body--divider">
            <!-- <ap-switch
                v-model="hasLandCharge"
                label="mit Grundschuld"
                show-state-labels /> -->
            <template v-if="hasLandCharge">
                <solar-financing-finder-extended-form />
                <hr class="solar-financing-finder--body--divider">
            </template>
            <div class="solar-financing-finder--body--footer">
                <div class="solar-financing-finder--body--footer--action-buttons">
                    <ap-button
                        label="Finanzierung Berechnen"
                        variant="dark"
                        :disabled="!isFormComplete"
                        :color="secondaryColor"
                        @click="calculateOffer()" />
                    <ap-button
                        label="Angebot Anfragen"
                        :color="primaryColor"
                        @click="openContactDialog()" />
                </div>
                <div v-if="showErrorMessage" class="solar-financing-finder--body--footer--error-message">
                    Mit den angegebenen Werten konnte leider keine passende Finanzierung gefunden werden.
                    Bitte passen Sie die Laufzeit und/oder das Eigenkapital an und versuchen Sie es erneut.
                </div>
            </div>
        </div>
        <solar-financing-finder-offer
            v-if="showOffer"
            :offer="offer"
            :is-loading="isLoading" />
    </component>
</template>

<script setup>
import { computed, defineExpose, ref, provide, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import SolarFinancingFinderHeader from './SolarFinancingFinderHeader.vue'
import SolarFinancingFinderMainForm from './SolarFinancingFinderMainForm.vue'
import SolarFinancingFinderExtendedForm from './SolarFinancingFinderExtendedForm.vue'
import SolarFinancingFinderOffer from './SolarFinancingFinderOffer.vue'
import SolarFinancingFinderContactDialog from '../../solar-financing-finder-contact-dialog/components/SolarFinancingFinderContactDialog.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'
import useSolarFinder from '../helpers/use-solar-finder'
import { SolarFinderKey } from '../injection-keys'

const store = useStore()
const route = useRoute()
const isEmbedded = computed(() => route.path.includes('embed'))
const renderedContainerElement = computed(() => isEmbedded.value ? 'div' : 'ap-tile')
const hasLandCharge = ref(false)
const isMainFormComplete = computed(() => 
    !!store.state.purchasePrice
    && !!store.state.expectedLoanDuration
    && !!store.state.maxOutput)
const isExtendedFormComplete = computed(() =>
    !!store.state.propertyTypeSolarFinancing
    && !!store.state.livingArea
    && !!store.state.constructionYear
    && !!store.state.zipCode
    && !!store.state.employmentType
    && !!store.state.monthlyIncome
    && !!store.state.dateOfBirth
    && !!store.state.preferredMonthlyRate)
const isFormComplete = computed(() => hasLandCharge.value
    ? isMainFormComplete.value && isExtendedFormComplete.value
    : isMainFormComplete.value)
const isLoading = ref(false)
const hasSentRequest = ref(false)
const offer = ref({})
const showOffer = computed(() => hasSentRequest.value || Object.keys(offer.value).length > 0)
const showErrorMessage = computed(() => hasSentRequest.value && Object.keys(offer.value).length === 0 && !isLoading.value)
const hasVerifiedEmail = computed(() => Boolean(route.query?.hasVerifiedEmail))
const leadId = computed(() => hasVerifiedEmail.value ? route.params?.companyId : undefined)
const solarFinderHelper = useSolarFinder()
const primaryColor = computed(() => solarFinderHelper.primaryColor.value)
const secondaryColor = computed(() => solarFinderHelper.secondaryColor.value)

const calculateOffer = async () => {
    if (isFormComplete.value) {
        const calculateOfferRequestVariant = hasLandCharge.value
            ? 'calculateOfferWithLandCharge'
            : 'calculateBasicOffer'
        isLoading.value = true
        hasSentRequest.value = true
        offer.value = await store.dispatch(calculateOfferRequestVariant)
        isLoading.value = false
    }
}

const openContactDialog = async () => {
    const sendRequestFunction = store._actions?.sendSolarFinancingRequest[0]

    await openDialog(SolarFinancingFinderContactDialog, {
        solarFinderHelper,
        financingRequest: {
            purchasePrice: store.state.purchasePrice,
            capital: store.state.capital,
            expectedLoanDuration: store.state.expectedLoanDuration?.value,
            maxOutput: store.state.maxOutput
        },
        hasVerifiedEmail: hasVerifiedEmail.value,
        sendRequestFunction,
    })
}

watchEffect(() => {
    store.commit('updateHasLandCharge', hasLandCharge.value) // TODO: use helper and remove this
})

onMounted(async () => {
    if (hasVerifiedEmail.value && leadId.value) {
        await store.dispatch('verifyEmailSolarFinancingRequest', leadId.value)
        await openContactDialog()
    }
})

provide(SolarFinderKey, solarFinderHelper)
defineExpose({
    companyName: solarFinderHelper.companyName,
    primaryColor: solarFinderHelper.primaryColor,
    secondaryColor: solarFinderHelper.secondaryColor,
})
</script>

<style lang="scss" scoped>
.solar-financing-finder {
    display: flex;
    flex-direction: column;

    &.ap-tile {
        border: unset; // TODO: remove this after moved to clean repo - this is because of old styling in app.vue
        padding: 0;
    }
}

.solar-financing-finder--body {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 60%;

    &.padding-bottom {
        padding-bottom: 3rem;
    }

    &--divider {
        align-self: center;
        border-bottom: 0.0625rem #C2C9D1 solid;
        margin: 0;
        width: 100%;
    }

    &--footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        text-align: center;
    }

    &--footer--action-buttons {
        display: flex;
        gap: 2rem;
        justify-content: center;
    }

    &--footer--action-buttons ::v-deep .ap-button {
        width: 16rem;
    }

    &--footer--error-message {
        color: #fe5f55;
        max-width: 45rem;
    }
}

.solar-financing-finder-header, .solar-financing-finder--body {
    padding: 1.5rem;
}

@media (max-width: 1200px) {
    .solar-financing-finder--body {
        width: 80%;
    }
}

@media (max-width: 680px) {
    .solar-financing-finder--body {
        width: 100%;
    
        &--footer--action-buttons {
            flex-wrap: wrap;
            gap: 1rem;
        }

        &--footer--action-buttons ::v-deep .ap-button {
            width: 100%;
        }
    }
}
</style>