<template>
    <div class="kunden-overview-header">
        <h4 class="kunden-overview-header--salutation">{{ salutation }}</h4>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const fristName = computed(() => store.state.user?.fristName ?? '')
const lastName = computed(() => store.state.user?.lastName ?? '')
const salutation = computed(() => {
    if (fristName.value && lastName.value) {
        return `Hallo ${fristName.value} ${lastName.value}`
    }
    return 'Hallo'
})
</script>

<style lang="scss" scoped>
.kunden-overview-header {
    margin-bottom: 2rem;

    &--salutation {
        color: #3B4868;
        font-weight: 700;
        margin: 0 0 0 0.5rem;
    }
}
</style>