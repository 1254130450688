<template>
    <div class="kunden-view">
        <kunden-view-header />
        <ap-spinner v-if="isLoading" />
        <template v-else>
            <customers-summary />
            <div class="kunden-view--divider"></div>
            <customer-deals />
        </template>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import KundenViewHeader from './Kunden/KundenViewHeader.vue'
import CustomersSummary from './Kunden/CustomersSummary.vue'
import CustomerDeals from './Kunden/CustomerDeals.vue'

const store = useStore()
const isLoading = ref(false)

const loadDeals = async () => {
    isLoading.value = true
    await store.dispatch('getDeals')
    isLoading.value = false
}

onMounted(async () => {
    await loadDeals()
})
</script>

<style lang="scss" scoped>
.kunden-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    min-height: 85vh;
    width: 70rem;

    &--divider {
        border-bottom: solid 0.0675rem #C2C9D1;
        margin-left: auto;
        margin-right: auto;
        width: 62.5rem;
    }
}
</style>