<template>
    <div class="solar-financing-finder-contact-dialog-form">
        <div class="solar-financing-finder-contact-dialog-form--header">
            <div class="solar-financing-finder-contact-dialog-form--header--airplane-icon">
                <img :src="airplaneIcon" />
            </div>
            <h3>
                Angebot Anfragen
            </h3>
            <p>
                Sie sind gleich bei Ihrem Finanzierungsangebot für eine Solaranlage.
                Wir melden uns innerhalb von 24 Stunden bei Ihnen.
            </p>
        </div>
        <div class="solar-financing-finder-contact-dialog-form--body">
            <ap-input
                v-model="email"
                label="E-Mail*"
                type="email"
                ref="emailRef"
                disallow-white-spaces
                @isValid="toggleIsValidEmail($event)" />
            <ap-input
                v-model="name"
                label="Name*"
                disallow-white-spaces
                @isValid="toggleIsValidName($event)" />
            <ap-input
                v-model="phone"
                label="Telefon"
                type="tel"
                @enter="sendRequest()" />
            <ap-checkbox v-model="hasAcceptedPrivacyPolicy">
                <template v-if="companyName">
                    Ich erkläre mich mit der <a href="https://www.acrossproperty.com/datenschutzerklaerung/" target="_blank" >Datenschutzerklärung</a> einverstanden, dass meine Daten von {{ companyName }}
                    an den Finanzierungspartner Across Property GbR weitergegeben werden.
                    Ihre Daten werden gespeichert, um Sie für Solaranlagenfinanzierung zu
                    kontaktieren und Vorschläge zu berechnen.
                </template>
                <template v-else>
                    Ich erkläre mich mit der <a href="https://www.acrossproperty.com/datenschutzerklaerung/" target="_blank" >Datenschutzerklärung</a> einverstanden.
                </template>
            </ap-checkbox>
            <p v-if="showErrorMessage" class="solar-financing-finder-contact-dialog-form--body--error-message">
                Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.
                Wenn der Fehler weiterhin besteht, versuchen Sie es bitte später nochmal.
                Vielen Dank für Ihre Geduld!
            </p>
        </div>
        <ap-button label="Anfrage Senden" full-width :disabled="!isValidForm" @click="sendRequest()" />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, inject, ref } from 'vue'
import airplaneIcon from '@/assets/airplane-icon.svg'
import { SolarFinderKey } from '@/components/solar-financing-finder/injection-keys'

defineProps({
    showErrorMessage: {
        type: Boolean,
        default: false,
    }
})

const { companyName } = inject(SolarFinderKey)
const email = ref()
const name = ref()
const phone = ref()
const hasAcceptedPrivacyPolicy = ref(false)
const isValidEmail = ref(false)
const isValidName = ref(false)
const isValidForm = computed(() => isValidEmail.value && isValidName.value && hasAcceptedPrivacyPolicy.value)

const toggleIsValidEmail = (isValid) => {
    isValidEmail.value = isValid
}

const toggleIsValidName = (isValid) => {
    isValidName.value = isValid
}

const sendRequest = () => {
    if (isValidForm.value) {
        emit('send-request', {
            email: email.value,
            name: name.value,
            phone: phone.value,
        })
    }
}

const emit = defineEmits(['send-request'])
</script>

<style lang="scss" scoped>
.solar-financing-finder-contact-dialog-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.solar-financing-finder-contact-dialog-form--header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

// TODO: use ap-icon as soon as moved to clean repo
    &--airplane-icon {
        align-items: center;
        background-color: #D1FADF;
        border: 0.375rem solid #ECFDF3;
        border-radius: 1.75rem;
        display: flex;
        height: 3rem;
        justify-content: center;
        margin-bottom: 0.5rem;
        width: 3rem;

        > img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.solar-financing-finder-contact-dialog-form--body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &--error-message {
        color: #fe5f55;
        margin-top: 1rem;
    }
}
</style>