<template>
    <div class="next-sale-container">
        <div class="next-sale--header">
            <div>
                <div><input class="small-text" type="text" name="" id="community-input" v-model="store.state.community" list="communities-list"></div>
                <div><label class="small-text" for="community-input">Landkreis</label></div>
                <div class="warning" v-if="isInputOptions">Bitte einen der Vorschläge auswählen</div>
                <datalist id="communities-list">
                    <div v-for="(option, index) in store.state.inputOptions.communities" :key="index">
                        <option :value="option" />
                    </div>
                </datalist>
            </div>
            <button class="btn button-ap-1" @click="store.dispatch('getNextSale')">Laden</button>
        </div>

        <h1>Next Sale</h1>

        <template v-if="!!store.state.nextSale">

            <div v-if="store.state.loadStateData == 3">
                Leider ist ein Fehler vorgefallen. Bitte versuchen Sie es erneut. Sollte der Fehler öfter vorkommen, versuchen Sie es später noch einmal.
            </div>

            <div v-else-if="store.state.loadStateData == 1" class="row justify-content-center">
                <ap-spinner />
            </div>

            <table v-else>
                <tr>
                    <th>Dringlichkeit</th>
                    <th>Im Markt seit</th>
                    <th>Geschätzte Marktverweilzeit</th>
                    <th>Kaufpreis</th>
                    <th>PLZ</th>
                    <th>Viertel</th>
                    <th>Wohnfläche</th>
                    <th>Zimmer</th>
                    <th>Firma</th>
                    <th>Ansprechpartner</th>
                    <th></th>
                    <th></th>
                    <th>Telefon</th>
                    <th>Link</th>
                </tr>
                <tr v-for="(listing, index) in nextSale" v-bind:key="index">
                    <td>{{ listing.urgency_to_call.toLocaleString('de') }}</td>
                    <td>{{ listing.dwell_at_the_time }} Tage</td>
                    <td>{{ listing.dwell_pred }} Tage</td>
                    <td>{{ listing.price.toLocaleString('de') }} €</td>
                    <td>{{ listing.postcode }}</td>
                    <td>{{ listing.district }}</td>
                    <td>{{ listing.livingArea.toLocaleString('de') }} m <sup>2</sup></td>
                    <td>{{ listing.rooms.toLocaleString('de') }}</td>
                    <td>{{ listing.contact.companyName }}</td>
                    <td>{{ listing.contact.salutation }}</td>
                    <td>{{ listing.contact.firstName }}</td>
                    <td>{{ listing.contact.lastName }}</td>
                    <td>{{ listing.contact.phone }}</td>
                    <td><a :href="listing.url" target="_blank">Inserat</a></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex'

const store = useStore()
const nextSale = computed(() => store.state.nextSale.data ?? [])

const isInputOptions = computed(() => !store.state.inputOptions.communities?.includes(store.state.commuinty))

onMounted(() => {
    store.dispatch('getInputOptions')
    store.dispatch('getNextSale')
})

</script>

<style scoped>
.next-sale-container {
    width: 100%;
    margin: 1rem;
}

.next-sale--header {
    display: flex;
    justify-content: space-between;
}


h2 {
    color: #3B4868;
    font-weight: 600;
}
h3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    color: #39B3B7;
}
.proposals-outer-box {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 1rem 0.5rem 0 0.5rem;
    padding: 2rem;
}

table {
    font-weight: 600;
}

tr {
    border-bottom: 2px solid #C2C9D1;
    height: 100px;
}
.proposal:hover {
    background-color: #eeeeee;
}

th {
    text-align: left;
    color: #3fe395;
    padding-bottom: 1.5rem;
    padding-left: 10px;
    cursor: pointer;
}

td {
    text-align: left;
    min-width: 150px;
    padding-left: 10px;
}

.bank-head {
    padding-bottom: 0;
    color: #3B4868;
}
.bank-field {
    max-width: 80px;
}
.unselected {
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 0;
    display: flex;
    align-items: center;
}
.selected {
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 0;
    display: flex;
    align-items: center;
    color: #3B4868;
}

.options {
    cursor: pointer;
    background-color: #eeeeee;
    border-radius: 0.5rem;
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
    border: none;
    transition: color 250ms, background-color 250ms, box-shadow 250ms;
}
.options:hover {
    /* color: #39B3B7; */
    background-color: #dfdfdf;
    color: #303951;

}
.options-open {
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #dfdfdf;
}
.options-open:hover {
    color: #303951;
    background-color: #eeeeee;
}
.finance-icon {
height: 1.2rem;
padding-right: 0.25rem;
}
.material-icons {
    padding-right: 0.5rem;
}
.spalten-anzeigen {
    font-weight: 600;
    padding-bottom: 1.5rem;
    color: #39B3B7;
}
.dropdown-options {
    background-color: #eeeeee;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    padding: 2rem 3rem 2rem 3rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    -webkit-animation: fadein 200ms; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 200ms; /* Firefox < 16 */
        -ms-animation: fadein 200ms; /* Internet Explorer */
         -o-animation: fadein 200ms; /* Opera < 12.1 */
            animation: fadein 200ms;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.option {
    width: 200px;
}
.spacer {
    padding: 1rem;
}
.europace {
    display: flex;

    align-items: end;
    flex-direction: column;
}
.europace-logo {
    width: 200px;
}
.notes {
    margin-top: 1rem;
    font-style: italic;
    font-size: 0.8rem;
}
</style>