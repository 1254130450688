<template>
    <div class="solar-financing-finder-embed">
        <div class="solar-financing-finder-embed--settings">
            <ap-tile>
                <h3 class="solar-financing-finder-embed--header">
                    Einstellungen zum Einbetten
                </h3>
                <div class="solar-financing-finder-embed--settings--content">
                    <ap-input v-model="displayCompanyName" label="Firmenname" />
                    <div class="solar-financing-finder-embed--settings--content--colors">
                        <ap-color-picker v-model="primaryColor" label="Primärfarbe:"/>
                        <ap-color-picker v-model="secondaryColor" label="Sekundärfarbe:"/>
                    </div>
                    <div class="solar-financing-finder-embed--settings--content--links">
                        <ap-copy-button
                            :model-value="codeSnippet"
                            label="Code zum Einbetten" />
                        <ap-copy-button
                            :model-value="shareLink"
                            label="Persönlicher Kundenlink" />
                    </div>
                </div>
            </ap-tile>
        </div>
        <ap-tile>
            <div class="solar-financing-finder-embed--description">
                <h3 class="solar-financing-finder-embed--header">
                    Hinweise zum Einbetten des Solarfinanzierungsfinders
                </h3>
                <p>
                    Für eine optimale Darstellung sollte die Breite des umfassenden Containers 
                    auf 100% eingestellt sein. Die Höhe des Inhalts variiert je nach 
                    Bildschirmgröße. Es wurde daher im iFrame code-snippet eine höhe von 900px vordefiniert.
                    Der code-snippet des iFrames muss bei korrekter Einbindung nicht modifiziert werden.
                </p>
                <p>
                    Der Inhalt aus dem Feld "Firmenname" wird lediglich im iFrame verwendet. Per Standardeinstellung
                    wird hier der Firmenname angezeigt, der bei uns im System als primärer Firmenname hinterlegt ist.
                </p>
                <p>
                    Falls Sie Fragen haben, wenden Sie sich bitte an Ihren Berater.
                </p>
            </div>
        </ap-tile>
    </div>
    <div class="solar-financing-finder-embed--visualization">
        <h3>Vorschau:</h3>
        <solar-financing-finder ref="solarFinancingFinderRef" />
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import SolarFinancingFinder from '../../solar-financing-finder/components/SolarFinancingFinder.vue'
import useSolarFinderEmbed from '../helpers/use-solar-finder-embed'

const store = useStore()
const companyId = computed(() => store.state.companyId)
const companyName = computed(() => store.state.user?.company.name) // TODO: change this later, this should come from the dedicated solar db info
const solarFinancingFinderRef = ref()

const {
    displayCompanyName,
    primaryColor,
    secondaryColor,
    codeSnippet,
    shareLink,
} = useSolarFinderEmbed(companyId.value, companyName.value)

watchEffect(() => {
    if (solarFinancingFinderRef.value) {
        solarFinancingFinderRef.value.companyName = displayCompanyName.value
        solarFinancingFinderRef.value.primaryColor = primaryColor.value
        solarFinancingFinderRef.value.secondaryColor = secondaryColor.value
    }
})
</script>

<style lang="scss" scoped>
// TODO: remove this after moved to clean repo - this is because of old styling in app.vue
h3 {
    font-weight: 700;
}

.solar-financing-finder-embed {
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding: 1rem 3rem;
}
.solar-financing-finder-embed--settings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 50rem;

    ::v-deep .ap-tile {
        align-items: center;
        border: unset; // TODO: remove this after moved to clean repo - this is because of old styling in app.vue
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 100%;
    }
    
    &--content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    
    &--content--colors {
        display: flex;
        gap: 2rem;
    }
    
    &--content--links {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 25rem;
    }
}

.solar-financing-finder-embed--description {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    // TODO: move to standarized styling for p tags somewhere more general
    > p {
        margin: 0;
    }
}

.solar-financing-finder-embed--visualization {
    padding: 1rem 3rem;
}
</style>