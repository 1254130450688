<template>
    <div class="customer-deals">
        <template v-if="hasDeals">
            <deal-tile
                v-for="(deal, index) in deals"
                :key="index"
                :deal="deal"/>
        </template>
        <h6 v-else class="customers-deals--header">
            Noch sind keine Kunden in Ihrer Pipeline... Nutzen Sie den Link oben um den Finanzierungsprozess Ihres nächsten Kunden Schritt für Schritt zu verfolgen.
        </h6>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import DealTile from './DealTile.vue'

const store = useStore()
const deals = computed(() => store.state.deals)
const hasDeals = computed(() => deals.value.length > 0)
</script>

<style lang="scss" scoped>
.customer-deals {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.customers-deals--header {
    font-weight: 700;
    margin: 0 0 0 0.5rem;
}
</style>
