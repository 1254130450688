<template>
    <div class="financing-request-details">
        <img :src="apLogo" class="financing-request-details--ap-logo" />
        <div class="financing-request-details--headers">
            <h6 class="financing-request-details--headers--preheader">
                Immobilie gefunden?
            </h6>
            <h1 class="financing-request-details--headers--main font-weight-600">
                Lasse Dich zu <div class="financing-request-details--headers--highlight">Deiner Finanzierung</div> beraten
            </h1>
        </div>
        <div class="financing-request-details--sell-list">
            <div v-for="(sellingPoint, index) in sellingPoints"
                :key="index"
                class="financing-request-details--sell-list--sell-point">
                <img class="financing-request-details--sell-list--sell-point--icon" :src="checkIcon" />
                <h6>{{ sellingPoint.content }}</h6>
            </div>
        </div>
        <div class="financing-request-details--contact-person">
            <img class="financing-request-details--contact-person--image" :src="contactPersonIcon" />
            <div class="financing-request-details--contact-person--information">
                <h5>Nikolas Vornehm</h5>
                <p>Dein persönlicher Finanzierungsberater</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import checkIcon from '../../assets/check-icon.svg'
import contactPersonIcon from '../../assets/contact-person-icon.svg'
import apLogo from '../../assets/ap-logo-2023.png'

const sellingPoints = ref([
    {
        content: "Wir vergleichen Angebote aus +500 Banken",
    },
    {
        content: "Schnelle und persönliche Bearbeitung von Anträgen",
    },
    {
        content: "Ohne Kosten für Dich",
    },
])
</script>

<style lang="scss" scoped>
.financing-request-details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 40rem;
    padding: 0 1.5rem;
    z-index: 1000;
}

.financing-request-details--ap-logo { 
    height: 5rem;
    padding-bottom: 2rem;
    width: max-content;
}

.financing-request-details--headers {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--preheader {
        color: #3FE395;
    }

    &--main {
        color: #3B4868;
        font-size: 3rem !important;
    }
    &--highlight {
        color: #3FE395;
        display: inline;
    }
}

.financing-request-details--sell-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &--sell-point {
        display: flex;
        gap: 0.5rem;
        margin-left: 0.5rem;
    }

    &--sell-point--icon {
        fill: #3B4868;
        height: 1rem;
        width: 1rem;
    }
}

.financing-request-details--contact-person {
    align-items: center;
    display: flex;
    gap: 1.5rem;

    &--image {
        height: 10rem;
        width: 10rem;
    }

    &--information {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

@media (max-width: 1080px) {
    .financing-request-details--headers--main {
        // TODO: !important because of styling override in app.vue. Remove when cleaning repo
        font-size: 2.3rem !important;
    }
}
</style>