<template>
    <div class="m-3 px-5 pt-4 pb-1 ap-tile" v-if="this.$store.state.chartsData.timeSupplyPerRoomsChart">
        <apexchart
            width="100%"
            type="line"
            :options="chartOptions"
            :series="series">
        </apexchart>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'

export default {
    name: 'TimeSupplyPerRoomsChart',

    setup() {
        const store = useStore()
        const chartData = computed(() => store.state.chartsData.timeSupplyPerRoomsChart)

        const location = computed(() => {
            let location = ""
            if (store.state.geoSearchCat == "community") {
                location = store.state.community
            } else if (store.state.geoSearchCat == "city") {
                location = store.state.city
            } else if (store.state.geoSearchCat == "postcode") {
                location = store.state.postcode
            }
            return location
        })

        const chartOptions = ref({

            chart: {
                id: "time-supply-chart",
                group: 'purchase-stats',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    export: {
                        svg: {
                            filename: `Marktangebot nach Anzahl Zimmer (Verkauf) in ${location.value}`
                        },
                        png: {
                            filename: `Marktangebot nach Anzahl Zimmer (Verkauf) in ${location.value}`
                        }
                    }
                },
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 1,
                    left: 0,
                    blur: 1,
                    color: '#000',
                    opacity: 0.1
                }
            },

            title: {
                text: "Marktangebot (Verkauf)",
                align: 'middle',
                style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#9699a2'
                },
            },

            subtitle: {
                text: store.state.timeChartSubtitle,
                align: 'middle',
                style: {
                    fontSize:  '12px',
                    fontWeight:  'normal',
                    fontFamily:  undefined,
                    color:  '#9699a2'
                },
            },

            xaxis: {
                categories: [],
                labels: {
                    hideOverlappingLabels: true,
                    rotate: 0,
                    offsetX: 0,
                },
                axisBorder: {
                    show: false,
                    color: 'grey',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: 'grey',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                },
            },

            yaxis: {
                opposite: true,
                axisBorder: {
                    show: false,
                    color: 'grey',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: false,
                    borderType: 'solid',
                    color: 'grey',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: "Anzahl Inserate",
                    rotate: -90,
                    offsetX: 2,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },

            grid: {
                show: true,
                borderColor: '#ced4da',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false,
                    }
                },   
                yaxis: {
                    lines: {
                        show: true
                    }
                },  
                padding: {
                    top: 0,
                    right: 20,
                    bottom: 20,
                    left: 0
                },  
            },

            legend: {
                position: 'top',
                horizontalAlign: 'left',
                floating: false,
                fontWeight: 700,
                markers: {
                    width: 12,
                    height: 2,
                    strokeWidth: 0,
                    radius: 0,
                    fillColors: ['#3fe396', '#39b3b7'],
                },
                labels: {
                    colors: undefined,
                    useSeriesColors: false,
                },
            },

            stroke: {
                show: true,
                curve: 'straight',
                lineCap: 'square',
                colors: ['#3fe396', '#39b3b7'],
                width: 2,
                dashArray: 0,
            },

            annotations: {
                images: [{
                    path: '../../assets/ap-logo.png',
                    x: 0,
                    y: 0,
                    width: 20,
                    height: 20,
                    appendTo: '.apexcharts-annotations'
                }],
            }
        })
        const series = ref([
            {
                name: "",
                data: [],
            },
        ])

        watch(chartData, (newChartData) => {
            series.value = newChartData.series
            chartOptions.value = {
                xaxis: {
                    categories: newChartData.labels,
                },
                subtitle: {
                    text: store.state.timeChartSubtitle
                },
            }
        })

        return {
            chartData,
            chartOptions,
            series,
        }
    },
}
</script>

<style scoped>
</style>