import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import { cities } from './assets/js/cities.js'

const canAccess = (state, getters, view) => {
    const userRight = state.nonAdminsViewRights.find(right => right.routes.includes(view))
    return getters.isAdmin || !!userRight?.users.includes(state.authentication.username)
}

const vueLocalStorage = new VuexPersistence({
    reducer: (state) => ({
        axiosConfig: state.axiosConfig,
        authentication: {
            username: state.authentication.username
        },
    }),
})

const store = createStore({

    state: {
        // GENEREL
        // backendUrl: 'http://127.0.0.1:8000',
        backendUrl: 'https://ap-mvp.herokuapp.com',

        axiosConfig: {
            headers: { Authorization: 'Bearer ' }
        },

        isAuthenticated: false,
        authentication: {
            username: '',
            password: '',
        },

        loginAttempts: 0,

        contact: {
            link: '',
            qrCode: '',
            realEstateCompanyHubspotId: '',
            realEstateAgentHubspotId: '',
        },

        nextSale: { hi: 'hi' },


        // access rights
		admins: [
			'nikolas.vornehm@acrossproperty.com',
			'r.yanzon@acrossproperty.com',
			'ben.hutchisonbird@acrossproperty.com'
		],

        nonAdminsViewRights: [
            {
                routes: [
                    'financing',
                    'customers',
                ],
                users: [
                    'r.lacroix@lacroix-realestate.com',
                    'abychow@outlook.de',
                    'info@av-immo.de',
                    'f.tannhoff@gmail.com',
                    'mh@hermann-living.de',
                    'kontakt@rozmarin-immobilien.de',
                    'adele.vetter@av-immo.de',
                    'info@immobavaria.de',
                    'immo@albert-strauss.de',
                    'pommerenke@finestep.de',
                    'fuerbeck@bavaria-wertimmobilien.de',
                    'marcus.hopfenzitz@amgimmobilien.de',
                    'goran.jancic@zenit-immobilien.de'
                ],
            },
            {
                routes: [
                    'solar-embed-settings'
                ],
                users: [
                    'r.lacroix@lacroix-realestate.com',
                ]
            }
        ],

        // FINANCE LEADS VARIABLES
        companyName: 'Across Property',
        companyId: '7045348069',
        pipelineStages: undefined,
        deals: [],
        earningsAgentExpected: 0,

        user: undefined,


        // DASHBOARD VARIBALES
        dashboardView: 'ReturnView',
        // input data filter
        geoSearchCat: 'community',
        community: 'Berlin, Stadt',
        city: 'Berlin',
        postcode: '10115',
        roomsMin: 1,
        roomsMax: 10,
        condition: 'ALLE',
        propertyType: 'Eigentumswohnung',
        timeframe: 'threeMonths',
        constructionYearMin: 1800,
        constructionYearMax: 2025,
        // charts subtitles
        timeChartSubtitle: '',
        roomsChartSubtitle: '',
        // input options
        inputOptions: {},
        // loading states
        loadStateData: 0, // 0: default, 1: loading, 2: done loading, 3: failed
        // chart data
        chartsData: {},

        // EVALUATION VARIABLES
        financingData: {
            propertyName: '',
            financingPurpose: "KAUF",
            price: undefined,
            modernizationCost: 0,
            propertyType: "EIGENTUMSWOHNUNG",
            comercialUsage: false,
            street: '',
            houseNumber: '',
            postcode: undefined,
            city: undefined,
            constructionYear: undefined,
            livingArea: undefined,
            rent: 500,
            isRentedOut: false,
            income: undefined,
            // courtageInPercent: 3.57,
        },
        financingProposals: [],
        financingProposalsSelected: [],

        customerData: {
            birthDate: undefined,
            income: undefined,
            ownFunds: undefined,
            securities: 0,
            monthlyRate: undefined,
            employmentType: 'ANGESTELLTER',
            fixedInterest: false,
            commitmentInterestFreeTime: false,
        },
        financingProposalsMakler: [],

        purchasePrice: 20000,
        capital: 0,
        expectedLoanDuration: {
            id: 10,
            title: '10 Jahre',
            value: 10,
        },
        maxOutput: 8,

        // TODO: this should come from backend (move there when main refactoring / moving to clean repo)
        propertyTypes: [
            {
                id: 'EIGENTUMSWOHNUNG',
                title: 'Eigentumswohnung',
            },
            {
                id: 'EINFAMILIENHAUS',
                title: 'Einfamilienhaus',
            },
            {
                id: 'DOPPELHAUSHAELFTE',
                title: 'Doppelhaushälfte',
            },
            {
                id: 'REIHENHAUS',
                title: 'Reihenhaus',
            },
            {
                id: 'ZWEIFAMILIENHAUS',
                title: 'Zweifamilienhaus',
            },
            {
                id: 'MEHRFAMILIENHAUS',
                title: 'Mehrfamilienhaus',
            },
            {
                id: 'BAUGRUNDSTUECK',
                title: 'Baugrundstück',
            },
        ],

        // TODO: this should come from backend (move there when main refactoring / moving to clean repo)
        employmentTypes: [
            {
                id: 'ANGESTELLTER',
                title: 'Angestellt',
            },
            {
                id: 'ARBEITER',
                title: 'Arbeitend',
            },
            {
                id: 'ARBEITSLOSER',
                title: 'Arbeitslos',
            },
            {
                id: 'BEAMTER',
                title: 'Verbeamtet',
            },
            {
                id: 'FREIBERUFLER',
                title: 'Freiberuflich',
            },
            {
                id: 'HAUSFRAU',
                title: 'Hausmann / Hausfrau',
            },
            {
                id: 'RENTNER',
                title: 'In Rente',
            },
            {
                id: 'SELBSTSTAENDIGER',
                title: 'Selbstständig',
            },
        ],

        propertyTypeSolarFinancing: undefined,
        livingArea: undefined,
        constructionYear: undefined,
        zipCode: undefined,
        employmentType: undefined,
        monthlyIncome: undefined,
        dateOfBirth: undefined,
        preferredMonthlyRate: undefined,

        hasLandCharge: false,
    },

    getters: {
        isAdmin(state) {
            return state.isAuthenticated && state.admins?.includes(state.authentication.username)
        },
        canAccessCustomers(state, getters) {
            return canAccess(state, getters, 'customers')
        },
        canAccessMarket(state, getters) {
            return canAccess(state, getters, 'market')
        },
        canAccessNextSale(state, getters) {
            return canAccess(state, getters, 'next-sale')
        },
        canAccessSolar(state, getters) {
            return canAccess(state, getters, 'solar-embed-settings')
        }
    },

    mutations: {
        setUsername(state, username) {
            state.authentication.username = username.toLowerCase()
        },
        setPassword(state, password) {
            state.authentication.password = password
        },
        setUser(state, user) {
            state.user = user
        },
        setError(state, error) {
            state.authentication.error = error
        },
        setIsAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated
        },
        setPipelineStages(state, data) {
            state.pipelineStages = data?.map(stage => {
                let title = ''
                if (stage.id === 'phase-1') {
                    title = 'Kundenqualifizierung'
                } else if (stage.id === 'phase-2') {
                    title = 'Antragsstellung'
                } else if (stage.id === 'phase-3') {
                    title = 'Provision Auszahlung'
                } else if (stage.id === 'phase-lost') {
                    title = 'Provisionsauszahlung'
                }
                return {
                    ...stage,
                    title
                }
            })
        },
        setDeals(state, data) {
            state.deals = data
        },
        setEarningsAgentExpected(state, data) {
            state.earningsAgentExpected = data
        },
        setGeoSearchCat(state, geoSearchCat) {
            state.geoSearchCat = geoSearchCat
        },
        setInputOptions(state, inputOptions) {
            state.inputOptions = inputOptions
        },
        setDashboardView(state, view) {
            state.dashboardView = view
        },
        setLoadStateData(state, loadStateData) {
            state.loadStateData = loadStateData
        },
        setChartsData(state, chartsData) {
            state.chartsData = chartsData
        },
        setChartsSubtitle(state, data) {

            let location = ""
            if (state.geoSearchCat == "community") {
                location = state.community
            } else if (state.geoSearchCat == "city") {
                location = state.city
            } else if (state.geoSearchCat == "postcode") {
                location = state.postcode
            }

            let propertyType = ""
            if (state.propertyType == "Eigentumswohnung") {
                propertyType = "Eigentumswohnungen"
            } else if (state.propertyType == "Haus") {
                propertyType = "Ein-/Zweifamilienhäuser"
            }

            let condition = ""
            if (state.condition == 'ALLE') {
                condition = ''
            } else {
                condition = state.condition
            }

            let constructionYearString = `Baujahre: ${state.constructionYearMin}-${state.constructionYearMax}`

            let roomsString = ""
            if (state.roomsMin == state.roomsMax) {
                roomsString = `Zimmer: ${state.roomsMin}`
            } else {
                roomsString = `Zimmer: ${state.roomsMin}-${state.roomsMax}`
            }

            let observationDatesString = `Beobachtung: ${data.timeReturnChart.labels[0]} - ${data.timeReturnChart.labels.slice(-1)}`

            state.timeChartSubtitle = `${location} -  ${propertyType} ${condition} - ${constructionYearString} - ${roomsString}`
            state.roomsChartSubtitle = `${location} -  ${propertyType} ${condition} - ${constructionYearString} - ${observationDatesString}`
        },
        clearFinancingProposals(state) {
            state.financingProposals = []
            state.financingProposalsSelected = []
        },
        pushFinancingProposals(state, proposals) {
            state.financingProposals.push(proposals)
            state.financingProposalsSelected.push({
                eigenkapitalInEuro: proposals.eigenkapitalInEuro,
                eigenkapitalInProzent: proposals.eigenkapitalInProzent,
                proposals: []
            })
        },
        setFinancingProposalsMakler(state, proposals) {
            state.financingProposalsMakler = proposals
        },
        setAxiosConfig(state, token) {
            state.axiosConfig.headers.Authorization = `Bearer ${token}`
        },
        setContact(state, contact) {
            state.contact = contact
        },
        setNextSale(state, nextSale) {
            state.nextSale = nextSale
        },
        updatePurchasePrice(state, price) {
            state.purchasePrice = price
        },
        updateCapital(state, amount) {
            state.capital = amount
        },
        updateLoanDuration(state, durationInYears) {
            state.expectedLoanDuration = durationInYears
        },
        updateMaxOutput(state, output) {
            state.maxOutput = output
        },
        updatePropertyTypeSolarFinancing(state, type) {
            state.propertyTypeSolarFinancing = type
        },
        updateLivingArea(state, area) {
            state.livingArea = area
        },
        updateConstructionYear(state, year) {
            state.constructionYear = year
        },
        updateZipCode(state, zipCode) {
            state.zipCode = zipCode
        },
        updateEmploymentType(state, employmentType) {
            state.employmentType = employmentType
        },
        updateMonthlyIncome(state, monthlyIncome) {
            state.monthlyIncome = monthlyIncome
        },
        updateDateOfBirth(state, dateOfBirth) {
            state.dateOfBirth = dateOfBirth
        },
        updatePreferredMonthlyRate(state, preferredMonthlyRate) {
            state.preferredMonthlyRate = preferredMonthlyRate
        },
        updateHasLandCharge(state, hasLandCharge) {
            state.hasLandCharge = hasLandCharge
        },
    },

    actions: {
        async getInputOptions({ state, commit }) {
            const response = await axios.get(state.backendUrl + '/input-options-api/')
            commit('setInputOptions', response.data.inputOptions)
        },

        async getNextSale({ state, commit }) {
            commit('setLoadStateData', 1)
            try {
                const { data } = await axios.post(state.backendUrl + '/next-sale/listings/', {
                    community: state.community,
                })
                commit('setNextSale', data)
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        async getData({ state, commit }) {
            if (
                (state.geoSearchCat == 'community' & state.inputOptions.communities?.includes(state.community)) ||
                (state.geoSearchCat == 'city' & cities?.includes(state.city)) ||
                (state.geoSearchCat == 'postcode' & state.inputOptions.postcodes?.includes(state.postcode))
            ) {
                commit('setLoadStateData', 1)
                try {

                    const response = await axios.post(state.backendUrl + '/input-options-api/', {
                        geoSearchCat: state.geoSearchCat,
                        community: state.community,
                        city: state.city,
                        postcode: state.postcode,
                        roomsMin: state.roomsMin,
                        roomsMax: state.roomsMax,
                        condition: state.condition,
                        propertyType: state.propertyType,
                        timeframe: state.timeframe,
                        constructionYearMin: state.constructionYearMin,
                        constructionYearMax: state.constructionYearMax,
                    })
                    commit('setChartsSubtitle', response.data)
                    commit('setChartsData', response.data)
                    commit("setLoadStateData", 2)
                } catch (error) {
                    console.log(error)
                    commit("setLoadStateData", 3)
                }
            }
        },

        async getUser({ state, commit }, userId) {
            try {
                const { data } = await axios.get(`${state.backendUrl}/users/${userId}`, state.axiosConfig)
                commit('setUser', data)
            } catch (error) {
                console.error(error)
            }
        },

        async getDeals({ state, commit }) {
            try {
                const userId = state.user?.userId
                if (userId) {
                    const response = await axios.get(`${state.backendUrl}/agent/${userId}/deals`, state.axiosConfig)
                    commit('setPipelineStages', response.data.pipelineStages)
                    commit('setDeals', response.data.deals)
                    commit('setEarningsAgentExpected', response.data.earningsAgentExpected)
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getFinancingProposals({ state, commit }) {
            commit('setLoadStateData', 1)
            commit('clearFinancingProposals')
            try {
                for (let i = 0; i < 3; i++) {
                    let ownFunds = (state.financingData.price + state.financingData.modernizationCost) * (i + 1) / 10
                    const response = await axios.post(state.backendUrl + '/baufi-api/', {
                        querryOptions: true,
                        financingPurpose: state.financingData.financingPurpose,
                        price: state.financingData.price,
                        modernizationCost: state.financingData.modernizationCost,
                        propertyType: state.financingData.propertyType,
                        comercialUsage: state.financingData.comercialUsage,
                        street: state.financingData.street,
                        houseNumber: state.financingData.houseNumber,
                        postcode: state.financingData.postcode,
                        city: state.financingData.city,
                        constructionYear: state.financingData.constructionYear,
                        livingArea: state.financingData.livingArea,
                        rent: state.financingData.rent,
                        isRentedOut: state.financingData.isRentedOut,
                        income: (state.financingData.price * 1.1 - ownFunds) * 0.09 / 12 * 3,
                        ownFunds: ownFunds,
                        courtageInPercent: state.financingData.courtageInPercent
                    })
                    commit('pushFinancingProposals', response.data)
                }
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        async getFinancingProposalsCustomerSpecific({ state, commit }) {
            commit('setLoadStateData', 1)
            commit('clearFinancingProposals')

            try {
                const { data } = await axios.post(state.backendUrl + '/baufi/customer-property-specific/', {
                    financingData: state.financingData,
                    customerData: state.customerData,
                }, state.axiosConfig)
                commit('setFinancingProposalsMakler', data.vorschlaege)
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        async createFinancingProposalOverview({ state, commit }) {
            commit('setLoadStateData', 1)
            try {
                await axios.post(state.backendUrl + '/baufi-overview-pdf/', {
                    companyName: state.companyName,
                    companyId: state.companyId,
                    financingData: state.financingData,
                    financingProposals: state.financingProposalsSelected,
                })
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        async getSession({ state, commit, dispatch }) {
            try {
                const { data } = await axios.get(state.backendUrl + '/session/', state.axiosConfig)
                commit('setIsAuthenticated', data.isAuthenticated)
                if (data.isAuthenticated) {
                    await dispatch('getQRCode')
                }
            } catch (error) {
                console.log(error)
                commit('setIsAuthenticated', false)
                state.authentication.password = ''
            }
        },

        async getQRCode({ state, commit }) {
            try {
                const { data } = await axios.post(state.backendUrl + '/qr-code/', {
                    email: state.authentication.username
                }, state.axiosConfig)
                commit('setContact', data)
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    'event': 'login',
                    'userId': data.realEstateAgentHubspotId
                })
                window.dataLayer.push({
                    'event': 'initTracking',
                })
            } catch (error) {
                console.log(error)
            }
        },

        async sendLoginCredentials({ state, commit, dispatch }) {
            try {
                const { data } = await axios.post(state.backendUrl + '/o/token/', {
                    grant_type: "password",
                    username: state.authentication.username,
                    password: state.authentication.password,
                    client_id: "bFkW4PzJWo67aQIE0aMvSNU73iC1onJjUOvHMkfy",
                    client_secret: "rq6AL8NuGjqNm0PbmiUeiKg3TK7PabAiZOhwOAom",
                    scope: "read"
                })
                commit('setAxiosConfig', data.access_token)
                await dispatch('getSession')
            } catch (error) {
                console.log(error)
            }
            state.loginAttempts += 1
        },

        async submitFinancingRequest({ state }, payload) {
            try {
                await axios.post(state.backendUrl + '/financing-request/', {
                    firstname: payload.firstname,
                    lastname: payload.lastname,
                    email: payload.email,
                    phone: payload.phone,
                    dataPrivacyChecked: payload.hasAcceptedPrivacyPolicy,
                    companyId: payload.companyId,
                    partnerId: payload.partnerId,
                    emailVerified: false,
                    newsletterChecked: true,
                })
                return true
            } catch (error) {
                return false
            }
        },

        async verifyEmail({ state }, payload) {
            try {
                const { data } = await axios.post(
                    `${state.backendUrl}/financing-request/email-verification/`,
                    payload)
                return data.verificationStatus
            } catch (error) {
                console.error(error)
                return 0
            }
        },

        async canUserAccess({ state, getters }, view) {
            return canAccess(state, getters, view)
        },

        async calculateBasicOffer({ state }) {
            const payload = {
                purchasePrice: state.purchasePrice,
                capital: state.capital,
                expectedLoanDurationInMonths: state.expectedLoanDuration.value * 12,
                maxOutput: state.maxOutput,
            }
            try {
                const { data } = await axios.post(`${state.backendUrl}/solar/financing-offer/basic`, payload)
                if (Object.keys(data).length > 0) {
                    return {
                        ...data,
                        totalLoanAmount: state.purchasePrice - state.capital,
                    }
                }
                return data
            } catch (error) {
                console.error(error)
            }
        },

        async calculateOfferWithLandCharge({ state }) {
            const payload = {
                purchasePrice: state.purchasePrice,
                capital: state.capital,
                expectedLoanDurationInMonths: state.expectedLoanDuration.value * 12,
                maxOutput: state.maxOutput,
                propertyTypeSolarFinancing: state.propertyTypeSolarFinancing,
                livingArea: state.livingArea,
                employmentType: state.employmentType.id,
                monthlyIncome: state.monthlyIncome,
                constructionYear: state.constructionYear,
                zipCode: state.zipCode,
                dateOfBirth: state.dateOfBirth,
                preferredMonthlyRate: state.preferredMonthlyRate,
            }
            try {
                const { data } = await axios.post(`${state.backendUrl}/solar/financing-offer/with-land-charge`, payload)
                return data
            } catch (error) {
                console.error(error)
            }
        },

        async sendSolarFinancingRequest({ state }, payload) {
            try {
                const { data } = await axios.post(`${state.backendUrl}/solar/financing-request`, {
                    ...payload,
                    companyId: state.companyId,
                })
                return data
            } catch (error) {
                console.error(error)
            }
        },

        async verifyEmailSolarFinancingRequest({ state }, leadId) {
            try {
                const { data } = await axios.post(`${state.backendUrl}/solar/financing-request/verify-email`, { leadId })
                return data
            } catch (error) {
                console.error(error)
            }
        },

        async sendOptInVerification({ state }, leadId) {
            try {
                const { data } = await axios.post(`${state.backendUrl}/opt-in/${leadId}`)
                return data
            } catch (error) {
                console.error(error)
            }
        }
    },

    plugins: [vueLocalStorage.plugin],

})

export default store