export default (intPlacesCounter, decimalPlacesCounter) => {
    let randomInt = Math.random()

    if (!intPlacesCounter || intPlacesCounter < 1) {
        intPlacesCounter = 0
    }

    let intPlaces = 1
    while(intPlacesCounter > 0) {
        intPlaces = intPlaces * 10
        --intPlacesCounter
    }

    if (!decimalPlacesCounter || decimalPlacesCounter < 1) {
        decimalPlacesCounter = 0
    }

    let decimalPlaces = 1
    while(decimalPlacesCounter > 0) {
        decimalPlaces = decimalPlaces * 10
        --decimalPlacesCounter
    }

    return Math.floor(randomInt * intPlaces * decimalPlaces) / decimalPlaces
}