<template>
    <div class="ap-checkbox">
        <input
            :value="modelValue"
            class="ap-checkbox--input"
            id="ap-checkbox--input-id"
            type="checkbox"
            @input="toggleCheckbox($event)" />
        <label class="ap-checkbox--label" for="ap-checkbox--input-id">
            <template v-if="label">
                {{ label }}
            </template>
            <slot v-else />
        </label>
    </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

defineProps({
    modelValue: Boolean,
    label: String,
})

const emit = defineEmits([
    'update:modelValue',
])

const toggleCheckbox = (event) => emit('update:modelValue', !!event?.target?.checked)
</script>

<style lang="scss" scoped>
.ap-checkbox {
    align-items: flex-start;
    display: flex;
    gap: 0.5rem;
}

.ap-checkbox--input {
    all: unset;
    background-color: white;
    border-radius: 0.125rem;
    border: 0.125rem solid #D9D9D9;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    display: grid;
    height: 1rem;
    margin-top: 0.125rem;
    padding: 0 0.375rem;
    place-content: center;
    transform: translateY(-0.075em);
    width: 1rem;

}

.ap-checkbox--input[type="checkbox"]::before {
    box-shadow: inset 1rem 1rem #3B4868;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    content: "";
    height: 0.65rem;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    width: 0.65rem;
}

.ap-checkbox--input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.ap-checkbox--input[type="checkbox"]:focus {
    outline: max(0.125rem, 0.15rem) solid #3B4868;
    outline-offset: max(0.125rem, 0.15rem);
}

.ap-checkbox--label {
    font-size: 0.75rem;
    pointer-events: none;
}
</style>