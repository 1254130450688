<template> 
    <ap-tile width="full-width" class="deal-status-stages">
        <div v-if="isClosedLost">{{ dealLostMessage }}</div>
        <div v-else v-for="stage in currentStages" :key="stage.id" class="deal-status-stages--stage">
            <div class="deal-status-stages--stage--icon-container">
                <img :class="getStyleForStageIcon(stage.displayOrder)" :src="getIconForStage(stage.displayOrder)" />
            </div>
            <div>
                {{ stage.label }}
            </div>
        </div>
    </ap-tile>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import statusActiveIcon from '../../assets/status-active.svg'
import statusPassedIcon from '../../assets/status-passed.svg'
import statusRemainingIcon from '../../assets/status-remaining.svg'
// import statusFailedIcon from '../../assets/status-failed.svg'

const props = defineProps({
    selectedStatus: Object,
    dealStage: String,
    closedLostReason: String,
})

const store = useStore()
const pipelineStages = computed(() => store.state.pipelineStages)
const currentStages = computed(() => props.selectedStatus.stages)
const isClosedLost = computed(() => props.selectedStatus.id === 'phase-lost')
const isDealLost = computed(() => props.dealStage === 'closedlost')
const stages = computed(() => pipelineStages.value.flatMap(phase => phase.stages))
const currentStageDisplayOrder = computed(() => stages.value.find(stage => stage.id === props.dealStage)?.displayOrder)
const dealLostMessage = computed(() => props.closedLostReason ?? 'Kunde wurde verloren.')

const getStyleForStageIcon = (displayOrder) => currentStageDisplayOrder.value === displayOrder ? 'active-icon' : 'inactive-icon'

const getIconForStage = (displayOrder) => {
    if (isDealLost.value) {
        return statusRemainingIcon
    }
    if (currentStageDisplayOrder.value === displayOrder) {
        return statusActiveIcon
    }
    if (currentStageDisplayOrder.value > displayOrder) {
        return statusPassedIcon
    }
    return statusRemainingIcon
}
</script>

<style lang="scss" scoped>
.deal-status-stages {
    display: flex;
    justify-content: space-between;

    &--stage {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--stage--icon-container {
        height: 2.25rem;
        width: 2.25rem;

        > .active-icon {
            height: 100%;
            width: 100%;
        }

        > .inactive-icon {
            height: 1.75rem;
            margin: auto;
            width: 1.75rem;
        }
    }
}
</style>