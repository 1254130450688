<template>
    <div class="solar-financing-finder-contact-dialog-verify-email">
        <h6>Fast geschafft!</h6>
        <h3>Überprüfe bitte <br>Dein E-Mail Postfach!</h3>
        <p class="solar-financing-finder-contact-dialog-verify-email--wait-for-mail">(*Es kann bis zu 10 Minuten dauern bis die E-Mail ankommt. Bitte Überprüfe auch Deinen Spam-Ordner.)</p>
        <div class="solar-financing-finder-contact-dialog-verify-email--icons">
            <img :src="letterBackIcon" class="solar-financing-finder-contact-dialog-verify-email--icons--letter-back" />
            <img :src="letterMiddleFloatIcon" class="solar-financing-finder-contact-dialog-verify-email--icons--letter-middle" />
            <img :src="letterFrontIcon" class="solar-financing-finder-contact-dialog-verify-email--icons--letter-front" />
        </div>
        <ap-status-bar :value="activeStatus" :statuses="statuses" />
        <div class="solar-financing-finder-contact-dialog-verify-email--email-not-received">
            Keine E-Mail erhalten? Bitte
            <a class="solar-financing-finder-contact-dialog-verify-email--email-not-received--link" :href="contactUsLink" target="_blank">hier</a>
            klicken.
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import externalAssets from '@/assets/external-assets'
import letterMiddleFloatIcon from '@/assets/letter-middle-float-icon.svg'
import letterBackIcon from '@/assets/letter-back-icon.svg'
import letterFrontIcon from '@/assets/letter-front-icon.svg'

const props = defineProps({
    statuses: Array,
})
const activeStatus = computed(() => props.statuses[1])
const contactUsLink = computed(() => externalAssets.links.acrossProperty.contactUs)
</script>

<style lang="scss" scoped>

@keyframes open-letter {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 4rem;
    }
    100% {
        bottom: 0;
    }
}

.solar-financing-finder-contact-dialog-verify-email {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1rem;
    text-align: center;

    &--wait-for-mail {
        // TODO: exchange color for variable once colours have been standardised
        color: #b6b6b6;
    }
}

.solar-financing-finder-contact-dialog-verify-email--icons {
    height: 13.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    width: 13rem;

    > img {
        position: absolute;
        right: 0;
        width: 100%;
    }

    &--letter-back {
        bottom: 0;
    }

    &--letter-front {
        bottom: 0;
    }

    &--letter-middle {
        animation: open-letter 4s ease-in-out infinite;
        bottom: 0rem;
    }
}

.solar-financing-finder-contact-dialog-verify-email--email-not-received {
    // TODO: exchange color for variable once colours have been standardised
    color: #b6b6b6;
    padding-top: 2.5rem;

    // TODO: remove once styles for a-tags have been standarized
    &--link {
        color: #39B3B7;
        font: inherit;
    }
}
</style>