<template>
    <div class="solar-financing-finder-offer">
        <div class="solar-financing-finder-offer--loan-summary">
            <div class="solar-financing-finder-offer--loan-summary--body">
                <div class="solar-financing-finder-offer--loan-summary--body--effective-interest-rate">
                    <h1 class="font-weight-700" :class="{ 'filter-blur': isLoading }">
                        {{ displayEffectiveInterestRate }}
                    </h1>
                    <h2 class="font-weight-700">
                        Effektivzins
                    </h2>
                </div>
                <div class="solar-financing-finder-offer--loan-summary--body--secondary-details">
                    <div class="solar-financing-finder-offer--loan-summary--body--secondary-details--monthly-rate">
                        <h6 class="font-weight-700">
                            monatliche Rate
                        </h6>
                        <h3 class="font-weight-700" :class="{ 'filter-blur': isLoading }">{{ displayMonthlyRate }}</h3>
                    </div>
                    <div class="solar-financing-finder-offer--loan-summary--body--secondary-details--total-loan-amount">
                        <h6 class="font-weight-700">
                            Kreditbetrag
                        </h6>
                        <h3 class="font-weight-700" :class="{ 'filter-blur': isLoading }">{{ displayTotalLoanAmount }}</h3>
                    </div>
                </div>
            </div>
            <hr class="solar-financing-finder-offer--loan-summary--divider">
            <div class="solar-financing-finder-offer--loan-summary--footer">
                <div class="solar-financing-finder-offer--loan-summary--footer--debit-interest-rate">
                    <h6 class="font-weight-700">
                        Sollzins
                    </h6>
                    <h6 class="font-weight-700" :class="{ 'filter-blur': isLoading }">
                        {{ displayDebitInterestRate }}
                    </h6>
                </div>
                <div class="solar-financing-finder-offer--loan-summary--footer--total-duration">
                    <h6 class="font-weight-700">
                        Gesamtlaufzeit
                    </h6>
                    <h6 class="font-weight-700" :class="{ 'filter-blur': isLoading }">
                        {{ displayTotalLoanDuration }}
                    </h6>
                </div>
            </div>
        </div>
        <div class="solar-financing-finder-offer--output-summary">
            <div class="solar-financing-finder-offer--output-summary--body">
                <div class="solar-financing-finder-offer--output-summary--body--monthly-savings">
                    <h6 class="font-weight-700">
                        ø Einsparung pro Monat
                    </h6>
                    <h3 class="font-weight-700" :class="{ 'filter-blur': isLoading }">{{ displayMonthlySavings }}</h3>
                </div>
                <div class="solar-financing-finder-offer--output-summary--body--monthly-produced-electricity">
                    <h6 class="font-weight-700">
                        ø produzierter Strom pro Monat
                    </h6>
                    <h3 class="font-weight-700" :class="{ 'filter-blur': isLoading }">{{ displayMonthlyProducedElectricity }}</h3>
                </div>
            </div>
            <div class="solar-financing-finder-offer--output-summary--footer">
                <span class="solar-financing-finder-offer--output-summary--footer--footnote">
                    *Berechnet mit einem ø Strompreis von 46,91 Cent/kWh
                </span>
                <span class="solar-financing-finder-offer--output-summary--footer--source">
                    Quelle: Bundesverband der Energie- und Wasserwirtschaft (BDEW) (Stand März 2023)
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watchEffect } from 'vue'
import generateRandomNumber from '@/essentials/helpers/generate-random-number'

const props = defineProps({
    offer: Object,
    isLoading: Boolean,
})

const displayEffectiveInterestRate = ref()
const displayDebitInterestRate = ref()
const displayMonthlyRate = ref()
const displayTotalLoanAmount = ref()
const displayTotalLoanDuration = ref()
const displayMonthlySavings = ref()
const displayMonthlyProducedElectricity = ref()

const effectiveInterestRate = computed(() => convertToLocale(props.offer?.effectiveInterestRate))
const debitInterestRate = computed(() => convertToLocale(props.offer?.debitInterestRate))
const monthlyRate = computed(() => convertToLocale(safeFloor(props.offer?.monthlyRate)))
const totalLoanAmount = computed(() => convertToLocale(props.offer?.totalLoanAmount))
const totalLoanDuration = computed(() => props.offer?.totalLoanDurationInMonths / 12)
const monthlySavings = computed(() => convertToLocale(safeFloor(props.offer?.monthlySavings?.value)))
const monthlyProducedElectricity = computed(() => convertToLocale(safeFloor(props.offer?.monthlyProducedElectricity?.value)))
const monthlyProducedElectricityUnit = computed(() => props.offer?.monthlyProducedElectricity?.unit)

const intervalId = ref()

const setRandomValues = () => {
    displayEffectiveInterestRate.value = `${generateRandomNumber(1,2)} %`
    displayDebitInterestRate.value = `${generateRandomNumber(1,2)} %`
    displayMonthlyRate.value = `${generateRandomNumber(3,0)} €`
    displayTotalLoanAmount.value = `${generateRandomNumber(5,0)} €`
    displayTotalLoanDuration.value = `${generateRandomNumber(2,0)} Jahre`
    displayMonthlySavings.value = `${generateRandomNumber(3,0)} €*`
    displayMonthlyProducedElectricity.value = `${generateRandomNumber(3,0)} kWh`
}

const setLoadingValues = () => {
    intervalId.value = setInterval(setRandomValues, 100)
}

const setCorrectValues = () => {
    clearInterval(intervalId.value)
    displayEffectiveInterestRate.value = `${effectiveInterestRate.value ?? '-'} %`
    displayDebitInterestRate.value = `${debitInterestRate.value ?? '-'} %`
    displayMonthlyRate.value = `${monthlyRate.value ?? '-'} €`
    displayTotalLoanAmount.value = `${totalLoanAmount.value || '-'} €`
    displayTotalLoanDuration.value = `${totalLoanDuration.value || '-'} Jahre`
    displayMonthlySavings.value = `${monthlySavings.value ?? '-'} €*`
    displayMonthlyProducedElectricity.value =
        `${monthlyProducedElectricity.value ?? '-'} ${monthlyProducedElectricityUnit.value ?? 'kWh'}`
}

const safeFloor = (number) => isNaN(number) ? '-' : Math.floor(number)
const convertToLocale = (value) => value?.toLocaleString('de')

watchEffect(() => {
    if (props.isLoading) {
        setRandomValues()
        setLoadingValues()
    } else {
        setCorrectValues()
    }
})

onMounted(() => {
    window.scrollTo(0, document.body.scrollHeight)
})
</script>

<style lang="scss" scoped>
.solar-financing-finder-offer {
    display: flex;
    
    .filter-blur {
        filter: blur(0.25rem);
    }
}

.solar-financing-finder-offer--loan-summary { 
    backdrop-filter: blur(0.6rem);
    background: var(--primary-color);
    box-shadow:
        -0.625rem 3.5rem 8.75rem rgba(143, 155, 186, 20%),
        inset 0 0.125rem 0.625rem rgba(0, 0, 0, 12%);
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    
    // TODO: !important is set because it's interfering with styles from App.vue - remove all tag styling and use general app-wide styling for these tags
    h1 {
        color: white !important;
        font-size: 3.5rem !important;
        margin: 0 !important;
    }
    
    h2 {
        color: white;
        font-size: 2rem;
        margin: 0 !important;
    }
    
    h3 {
        color: white !important;
        font-size: 2rem !important;
        margin: 0 !important;
    }
    
    h6 {
        color: white !important;
        margin: 0 !important;
    }
    
    &--body {
        align-items: center;
        align-self: center;
        display: flex;
        height: 13rem;
        justify-content: space-between;
        max-width: 40rem;
        padding: 3.75rem 0 2.5rem;
        width: 80%;
    }

    &--body--effective-interest-rate {
        color: white;
        display: flex;
        flex-direction: column;
        max-width: 12.5rem;
    }

    &--body--secondary-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-width: 21rem;
    }

    &--body--secondary-details--monthly-rate,
    &--body--secondary-details--total-loan-amount {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &--divider {
        border-top: 0.125rem white solid;
        color: white;
        opacity: 100%;
    }

    &--footer {
        align-self: center;
        display: flex;
        justify-content: space-between;
        max-width: 40rem;
        padding-bottom: 1.5rem;
        width: 80%;
    }

    &--footer--debit-interest-rate {
        display: flex;
        gap: 3rem;
        max-width: 12.5rem;
    }

    &--footer--total-duration {
        display: flex;
        gap: 3rem;
        max-width: 21rem;
    }
}

.solar-financing-finder-offer--output-summary {
    background-color: #EBF7F8;
    box-shadow:
        -0.625rem 3.5rem 8.75rem rgba(143, 155, 186, 20%),
        inset 0 0.125rem 0.625rem rgba(0, 0, 0, 12%);
    backdrop-filter: blur(0.6rem);
    display: flex;
    flex-direction: column;
    flex: 1 2 auto;
    justify-content: space-between;

    // TODO: !important is set because it's interfering with styles from App.vue - remove all tag styling and use general app-wide styling for these tags
    h3 {
        color: var(--secondary-color) !important;
        font-size: 2rem !important;
        margin: 0 !important;
    }
    
    h6 {
        margin: 0 !important;
    }

    &--body {
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 13rem;
        justify-content: center;
        padding: 3.75rem 0 2.5rem;
        width: 25rem;
    }

    &--body--monthly-savings,
    &--body--monthly-produced-electricity {
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 2rem;
    }

    &--footer {
        align-items: flex-end;
        align-self: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        width: 80%;
    }

    &--footer--footnote {
        font-size: 0.625rem;
    }

    &--footer--source {
        font-size: 0.5rem;
    }
}

@media (max-width: 1200px) {

    .solar-financing-finder-offer--loan-summary { 

        &--body--secondary-details {
            min-width: 0;
        }

        &--body--secondary-details--monthly-rate,
        &--body--secondary-details--total-loan-amount {
            align-items: flex-end;
            flex-direction: column-reverse;
        }

        &--footer--debit-interest-rate,
        &--footer--total-duration {
            flex-direction: column;
            gap: 1rem;

        }
    }       
}

@media (max-width: 980px) {
    .solar-financing-finder-offer {
        flex-direction: column;
    }
    .solar-financing-finder-offer--output-summary--body {
        width: 80%;
    }
}

@media (max-width: 680px) {
    .solar-financing-finder-offer--loan-summary {

        // TODO: !important is set because it's interfering with styles from App.vue - remove all tag styling and use general app-wide styling for these tags
        h1 {
            font-size: 2.5rem !important;
        }

        h2 {
            font-size: 1.2rem !important;
        }

        h6 {
            text-align: right;
        }

        &--body {
            gap: 1rem;
        }
    }

    .solar-financing-finder-offer--output-summary--body {

        &--monthly-savings,
        &--monthly-produced-electricity {
            align-items: flex-start;
            flex-direction: column-reverse;
            gap: 0;
        }
    }
}
</style>