<template>
    <div class="m-3 px-5 pt-4 pb-1 ap-tile" v-if="chartData">
        <apexchart
            width="100%"
            type="area"
            :options="chartOptions"
            :series="series"
            >
        </apexchart>
    </div>
</template>

<script setup>
import { defineProps, watch, ref, toRef } from 'vue'

const props = defineProps({
    chartData: {
        type: Object,
        default: undefined,
    },
    id: {
        type: String,
        required: true
    },
    filename: {
        type: String,
        default: 'across-property-chart',
    },
    group: {
        type: String,
        default: '',
    },
    zoomEnabled: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: ''
    },
    xaxisCategories: {
        type: Object,
        default: undefined,
    },
    xaxisTitle: {
        type: String,
        default: '',
    },
    yaxisTitle: {
        type: String,
        default: '',
    },
    yaxisUnit: {
        type: String,
        default: '',
    },
})
const chartData = toRef(props, 'chartData')
const idProp = toRef(props, 'id')
const filenameProp = toRef(props, 'filename')
const groupProp = toRef(props, 'group')
const zoomEnabledProp = toRef(props, 'zoomEnabled')
const titleProp = toRef(props, 'title')
const subtitleProp = toRef(props, 'subtitle')
const xaxisCategoriesProp = toRef(props, 'xaxisCategories')
const xaxisTitleProp = toRef(props, 'xaxisTitle')
const yaxisTitleProp = toRef(props, 'yaxisTitle')
const yaxisUnitProp = toRef(props, 'yaxisUnit')
const isFilledProp = toRef(props, 'isFilled')



function chart(id, filename, group, zoomEnabled) {
    return {
        id: id,
        group: group,
        zoom: {
            enabled: zoomEnabled,
        },
        toolbar: {
            export: {
                svg: {
                    filename: filename
                },
                png: {
                    filename: filename
                }
            }
        },
        dropShadow: {
            enabled: true,
            top: 1,
            left: 0,
            blur: 1,
            color: '#000',
            opacity: 0.1
        }
    }
}

function title(titleText) {
    return {
        text: titleText,
        align: 'middle',
        style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            fontFamily:  'Outfit, Inter, Open Sans, sans-serif',
            color:  '#9699a2'
        },
    }
}

function subtitle(subtitleText) {
    return {
        text: subtitleText,
        align: 'middle',
        style: {
            fontSize:  '12px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            color:  '#9699a2'
        },
    }
}

function xaxis(xaxisTitleText, categories) {
    return {
        categories: categories,
        labels: {
            hideOverlappingLabels: true,
        },
        title: {
            text: xaxisTitleText,
            rotate: -90,
            style: {
                color: '#9699a2',
                fontSize: '12px',
                fontFamily: 'Outfit, Inter, Open Sans, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-xaxis-title',
            },
        },
        axisBorder: {
            show: true,
            color: '#9699a2',
        },
        axisTicks: {
            show: true,
            color: '#9699a2',
            height: 6,
        },
    }
}

function yaxis(yaxisTitleText, min, max) {
    return {
        show: true,
        min: min,
        max: max,
        title: {
            text: yaxisTitleText,
            rotate: -90,
            style: {
                color: '#9699a2',
                fontSize: '12px',
                fontFamily: 'Outfit, Inter, Open Sans, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
        labels: {
            show: true,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    }
}

function grid() {
    return {
        show: false,
    }
}

function legend() {
    return {
        position: 'top',
        horizontalAlign: 'left',
        floating: false,
        fontWeight: 700,
        markers: {
            width: 12,
            height: 2,
            strokeWidth: 0,
            radius: 0,
            fillColors: ['#3fe396', 'white'],
        },
        labels: {
            colors: undefined,
            useSeriesColors: false,
        },
    }
}

function stroke() {
    return {
        show: true,
        curve: 'straight',
        lineCap: 'square',
        colors: ['#3fe396'],
        width: 2,
        dashArray: 0,
    }
}

// function annotations() { // NOT WORKING YET
//     return {
//         images: [{
//             path: '../../assets/ap-logo.png',
//             x: 0,
//             y: 0,
//             width: 20,
//             height: 20,
//             appendTo: '.apexcharts-annotations'
//         }],
//     }
// }

function fill() {
    return {
        colors: ['#3fe396'],
        type: 'gradient',
    }
}

function markers() {
    return {
        size: 1,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
            size: undefined,
            sizeOffset: 3
        }
    }
}

function dataLabels(unit) {
    return {
        enabled: true,
        formatter: function (val) {
            return `${val.toLocaleString('de')} ${unit.value}`
        },
        textAnchor: 'left',
        offsetX: 0,
        offsetY: -7,
        style: {
            fontSize: '12px',
            fontFamily: 'Outfit, Inter, Open Sans, sans-serif',
            fontWeight: 'normal',
            colors: ['#3fe396']
        },
    }
}

const chartOptions = ref({
    chart: chart(idProp, filenameProp, groupProp, zoomEnabledProp),
    title: title(titleProp),
    subtitle: subtitle(subtitleProp),
    xaxis: xaxis(xaxisTitleProp, xaxisCategoriesProp),
    yaxis: yaxis(yaxisTitleProp, 0, 5),
    grid: grid(),
    legend: legend(),
    stroke: stroke(),
    // annotations: annotations(),
    fill: fill(isFilledProp),
    markers: markers(),
    dataLabels: dataLabels(yaxisUnitProp),
})

const series = ref([
    {
        name: "",
        data: [],
    },
])

watch(chartData, (newChartData) => {
    series.value = newChartData.series
    const ymin = Math.min.apply(null, Object.values(series.value[0].data)) * 0.9
    const ymax = Math.max.apply(null, Object.values(series.value[0].data)) * 1.1

    chartOptions.value = {
        chart: chart(idProp, filenameProp, groupProp, zoomEnabledProp),
        title: title(titleProp),
        subtitle: subtitle(subtitleProp),
        xaxis: xaxis(xaxisTitleProp, newChartData.labels),
        yaxis: yaxis(yaxisTitleProp, ymin, ymax),
        grid: grid(),
        legend: legend(),
        stroke: stroke(),
        // annotations: annotations(),
        fill: fill(isFilledProp),
        dataLabels: dataLabels(yaxisUnitProp),
    }
})
</script>

<style scoped>
</style>