<template>
    <div class="deal-tile-general-details">
        <div class="deal-tile-general-details--expected-earnings">
            <h6 class="font-weight-600">Erwartete Provision (brutto):</h6>
            <ap-tile>
                <h6 class="deal-tile-general-details--expected-earnings--amount font-weight-600">
                    {{ expectedAgentEarningsMessage }}
                </h6>
                <span class="deal-tile-general-details--expected-earnings--calculation">
                    {{ commissionSubheader }}
                </span>
            </ap-tile>
        </div>
        <div class="deal-tile-general-details--expected-dates">
            <div
                v-for="(expectedDate, index) in expectedDates"
                :key="index"
                class="deal-tile-general-details--expected-dates--date">
                <h6 class="font-weight-600">
                    {{ expectedDate.title }}
                </h6>
                <h6>
                    {{ expectedDate.date }}
                </h6>
            </div>
            <div class="deal-tile-general-details--expected-dates--success-probability">
                <h6 class="font-weight-600">
                    Erfolgswahrscheinlichkeit:
                </h6>
                <div>
                    {{ successProbability }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import convertToLocaleCurrency from '@/essentials/helpers/convert-to-locale-currency'

const props = defineProps({
    commissionAgent: Number,
    commissionAp: Number,
    partnerCommissionInPercent: Number,
    closeDate: Date,
    payDate: Date,
    successProbability: Number,
})

const expectedAgentEarningsMessage = computed(() => convertToLocaleCurrency(props.commissionAgent))
const displayCommissionAp = computed(() => convertToLocaleCurrency(props.commissionAp))
const commissionSubheader = computed(() => `${props.partnerCommissionInPercent}% von ${displayCommissionAp.value}`)
const successProbability = computed(() => `${props.successProbability * 100}%`)

const expectedDates = computed(() => [
    {
        title: 'Erwartete Vertragsunterzeichnung:',
        date: formatDate(props.closeDate),
    },
    {
        title: 'Erwartete Provisionsauszahlung:',
        date: formatDate(props.payDate),
    }
])

const formatDate = (date) => {
    if (date) {
        return (new Date(date)).toLocaleDateString('de')
    }
    return 'nicht verfügbar'
}
</script>

<style lang="scss" scoped>
.deal-tile-general-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    ::v-deep .ap-tile {
        background-color: #EBF7F8;
        color: #3B4868;
        padding: 0.5rem 1rem 0.25rem;
    }
}

.deal-tile-general-details--expected-earnings {
    text-align: right;

    &--amount {
        margin: 0;
    }

    &--calculation {
        font-size: 0.625rem;
    }
}

.deal-tile-general-details--expected-dates {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.deal-tile-general-details--expected-dates--date {
    border-bottom: solid 0.0675rem #E4E7ED;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.deal-tile-general-details--expected-dates--success-probability {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}
</style>