<template>
    <h1>Marktansicht</h1>
    <div class="row">
        <div class="col-6 mt-5">
            <h2 class="d-flex justify-content-center">Kauf</h2>
            <area-chart
                :chartData="timePriceChart"
                id="time-price-chart"
                filename="Quadratmeterpreis"
                title="Quadratmeterpreis"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Preis pro qm'
                yaxisUnit="€" />
            <area-chart
                :chartData="timeSupplyChart"
                id="time-price-chart"
                filename="Marktangebot (Verkauf)"
                title="Marktangebot (Verkauf)"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Anzahl Inserate'
                yaxisUnit="" />
            <area-chart
                :chartData="timeDwellChart"
                id="time-price-chart"
                filename="Marktverweilzeiten (Kaufnachfrage)"
                title="Marktverweilzeiten (Kaufnachfrage)"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Tage bis Verkauf**'
                yaxisUnit="" />
        </div>
        <div class="col-6 mt-5">
            <h2 class="d-flex justify-content-center">Miete</h2>
            <area-chart
                :chartData="timeRentChart"
                id="time-price-chart"
                filename="Quadratmetermiete"
                title="Quadratmetermiete"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Preis pro qm'
                yaxisUnit="€" />
            <area-chart
                :chartData="timeSupplyRentChart"
                id="time-price-chart"
                filename="Marktangebot (Vermietung)"
                title="Marktangebot (Vermietung)"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Anzahl Inserate'
                yaxisUnit="" />
            <area-chart
                :chartData="timeDwellRentChart"
                id="time-price-chart"
                filename="Marktverweilzeiten (Mietnachfrage)"
                title="Marktverweilzeiten (Mietnachfrage)"
                :subtitle="subtitleTimeCharts"
                yaxisTitle='Tage bis Vermietung**'
                yaxisUnit="" />
        </div>
    </div>
</template>

<script setup>
import AreaChart from './AreaChart.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const subtitleTimeCharts = computed(() =>  store.state.timeChartSubtitle)
const timePriceChart = computed(() => store.state.chartsData.timePriceChart)
const timeSupplyChart = computed(() => store.state.chartsData.timeSupplyChart)
const timeDwellChart = computed(() => store.state.chartsData.timeDwellChart)
const timeRentChart = computed(() => store.state.chartsData.timeRentChart)
const timeSupplyRentChart = computed(() => store.state.chartsData.timeSupplyRentChart)
const timeDwellRentChart = computed(() => store.state.chartsData.timeDwellRentChart)
</script>